$fontOsm: "fontawesome";
$datepicker__background-color: #f0f0f0 !default;
$datepicker__border-color: #aeaeae !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: #216ba5 !default;
$datepicker__text-color: #000 !default;
$datepicker__header-color: #000 !default;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%) !default;

$datepicker__border-radius: 0.3rem !default;
$datepicker__day-margin: 0.166rem !default;
$datepicker__font-size: 0.8rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$datepicker__item-size: 1.7rem !default;
$datepicker__margin: 0.4rem !default;
$datepicker__navigation-size: 0.45rem !default;
$datepicker__triangle-size: 8px !default;

// Color Variables
$white: #fff;
$antiFlashWhite: #f2f2f2;
$titanWhite: #f8f8ff;
$alto: #dddddd;
$gallery: #f0f0f0;
$cultured: #f6f6f6;
$alabaster: #f7f7f7;
$ghostWhite: #f9f9f9;
$wildSand: #f5f5f5;
$selago: #f8f8fe;
$red: #ff0000;
$vividRed: #ff1212;
$persianRed: #c72c2c;
$black: #000;
$eerieBlack: #181818;
$raisinBlack: #252525;
$blue: #3877ac;
$aliceBlue: #f9fafb;
$darkBlue: #0000ff;
$trueBlue: #0669de;
$blueRibbon: #003eff;
$crayola: #0050ba;
$catalinaBlue: #0c3375;
$orange: #f26b0b;
$blazeOrange: #fc6500;
$bronze: #d07c3b;
$texasRose: #feac5e;
$gray: #4c545c;
$codGrey: #0f0f0f;
$davysGrey: #555;
$trout: #4c545c;
$darkLiver: #515151;
$bgcolor: #f3f4f6;
$darkbgcolor: #434343;
$fadedGrey: #6b6c72;
$lightGrey: #d0d2d9;
$athensGrey: #f2f4f6;
$blackOlive: #3d3d3d;
$doveGrey: #666666;
$darkGrey: #444;
$dustyGrey: #999;
$darkGreyX11: #aaaaaa;
$outerSpace: #484848;
$taupeGray: #888;
$chineseBlack: #0f131b;
$darkGunmetal: #20272f;
$silver: #ccc;
$silverSand: #c5c5c5;
$chineseSilver: #cfcfcb;
$graniteGrey: #606060;
$spanishGrey: #959595;
$linen: #fff0e6;
$silverChalice: #adadad;
$sonicSilver: #727579;
$philippineSilver: #b3b3b3;
$darkCharcoal: #313131;
$manatee: #9ca1ac;
$electricGreen: #00ff04;
$green: green;
$verseGreen: #148914;
$chineseGreen: #dad55e;
$mustardGreen: #777620;
$olive: #847b02;
$arsenic: #434343;
$cornSilk: #fef7e1;
$pastelYellow: #fffa90;
$tundora: #454545;
$mineShaft: #2b2b2b;
$lightSalmonPink: #f1a899;
$mistyRose: #fddfdf;
$congoBrown: #5f3f3f;
$brown: #a83407;
$lotion: #fcfcfc;
$linkWater: #e6ebf792;
$astral: #3878ac1f;
$shark: #212529;
$silverFoil: #aeaeae;
$interdimensionalBlue: #3003e1;
$deepViolet: #180270;
$lightGreen: #91e19e;
$caledon: #aef2b9;
$melon: #f6c2b4;
$tumbleweed: #e0a595;
$sunny: #feff7f;
$ghost: #ced4da;
$cobalt: #064cb5;
$nickel: #737171;
$timberwolf: #ded6d6;
$platinum: #e4e2e2;
$lavender: #e9e6f7;

// Body Needs Variables
$fontSize: 15px;
$textColor: #434343;
$fontFamily: var(--font-inter);
$fontFamilyPop: var(--font-poppins);
// $fontFamilyAnton: "Anton", sans-serif;

// Media Query Variables
$xs: 374px;
$sm: 575px;
$md: 767px;
$lg: 991px;
$xl: 1199px;
$xxl: 1440px;
$xxl2: 1440px;

.word-break-all {
  word-break: break-all;
}
.color-orange,
.text-orange {
  color: $orange;
}
.text-gray {
  color: #686868;
}
.bg-orange {
  background-color: $orange;
}
.bg-white {
  background-color: #fff;
}
.bg-gray {
  background-color: #f4f5f9;
}
.bg-black {
  background-color: #000 !important;
  background: #000;
}
.text-black {
  color: #000;
}
.text-white {
  color: #fff;
}
.text-shadow-orange {
  text-shadow: 3px 0 $orange, -3px 0 $orange, 0 3px $orange, 0 -3px $orange, 2px 2px $orange, -2px -2px $orange,
    2px -2px $orange, -2px 2px $orange;
}
.fw-9 {
  font-weight: 900;
}
.fw-8 {
  font-weight: 800;
}
.fw-7 {
  font-weight: 700;
}
.fw-6 {
  font-weight: 600;
}
.fw-5 {
  font-weight: 500;
}
.fw-4 {
  font-weight: 400;
}
.fs-48 {
  font-size: 3rem;
}
.fs-1 {
  font-size: 0.25rem;
}
.fs-2 {
  font-size: 0.5rem;
}
.fs-3 {
  font-size: 0.75rem;
}
.fs-4 {
  font-size: 1rem;
}
.fs-5 {
  font-size: 1.25rem;
}
.fs-6 {
  font-size: 1.5rem;
}
.fs-7 {
  font-size: 1.75rem;
}
.fs-8 {
  font-size: 2rem !important;
}
.font-4 {
  font-size: 4rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}
.font-5 {
  font-size: 5rem;
  @media (max-width: 768px) {
    font-size: 3rem;
  }
}
.font-6 {
  font-size: 6rem;
  @media (max-width: 768px) {
    font-size: 4rem;
  }
}
.font-7 {
  font-size: 7rem;
  @media (max-width: 768px) {
    font-size: 5rem;
  }
}
.input {
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid black;
  &::placeholder {
    color: #ccc;
  }
  &.text-black {
    color: #000;
    &::placeholder {
      color: #383737;
    }
  }
  &.transparent {
    background: transparent;
  }
}

.outline {
  outline: none;
  background: transparent;
  color: white;
  border: 1px solid #ccc;
  // on click it should glow its border
  &:focus {
    border: 1px solid $orange;
  }
}
.p-10-15 {
  padding: 10px 15px !important;
}
.px-sm-4 {
  @media (max-width: 768px) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.px-sm-6 {
  @media (max-width: 768px) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
.is-link {
  text-decoration: none;
  &:hover {
    color: $orange !important;
    cursor: pointer;
    transition: all 0.3s;
    img {
      filter: blur(0px) !important;
      transition: all 0.3s;
      transform: scale(1.05);
    }
  }
}
.hover-zoom {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }
}
.hover-white {
  transition: all 0.3s;
  &:hover {
    color: white;
  }
}
.box-shadow-1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.box-shadow-inner {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px inset;
}
.glow-border:hover {
  box-shadow: $orange 0px 0px 0px 3px;
  transition: all 0.3s;
}
.border-black {
  border-color: #000 !important;
}
.b-radius-1 {
  border-radius: 0.25rem;
}
.b-radius-2 {
  border-radius: 0.5rem !important;
}
.b-radius-4 {
  border-radius: 1rem !important;
}
.border-radius-10 {
  border-radius: 10px;
}
.border-radius-32 {
  border-radius: 2rem;
}
.black-blue-gradient {
  background: #000;
  background: linear-gradient(90deg, rgba(0, 0, 4, 0.975), rgba(1, 1, 25, 0.93) 62%, rgba(0, 0, 37, 0.944));
}
.scrollbar-none {
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.animate-stars svg {
  //create glitering effect with zoom in and out effect and color change. this class will contain star icon from react-icons. the effect should be on hover with some little x and y axis movement.
  transition: all 0.8s;
  border-radius: 50%;

  &:hover {
    padding: 2px;
    animation: star 1s reverse;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px inset;
    transform: scale(1.2);

    @keyframes star {
      0% {
        color: $orange;
        transform: scale(1);
      }
      25% {
        color: white;
        transform: scale(1.3);
        border: 1px solid $orange;
      }
      50% {
        color: white;
        transform: scale(1.2);
        background-color: $orange;
      }
      100% {
        color: $orange;
        transform: scale(1.1);
        border: 2px solid $orange;
      }
    }
  }
}
.h-auto {
  height: auto;
}
