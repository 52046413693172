// March Madness Landing Page Styles
.march-madness-active-btn {
  font-weight: 600;
  color: $orange;
}

.march-madness-btn {
  font-weight: 600;
  font-size: 14px;
  color: $fadedGrey;
}

.march-madness-main-btn {
  margin-right: 100px;
}

.march-madness-secondary-btn {
  margin-right: 40px;
}

.mm-primary-text {
  font-weight: 700;
  font-size: 1.2rem;
  color: $chineseBlack;
}

.mm-description {
  font-weight: 500;
  font-size: 16px;
  color: $fadedGrey;
}

.mm-desc-card {
  margin: 40px 0;
  width: fit-content;
}

.mm-email-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.mm-email-input-wrapper {
  border-left: none;
  font-size: 14px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0px;
  padding-left: 0px;
  border-top: 1px solid $ghost;
  border-bottom: 1px solid $ghost;
  border-bottom-left-radius: 0px;
  width: 350px;
  display: flex;
  @media (min-width: 770px) {
    justify-content: center;
  }
}

.mm-access-button {
  @media (max-width: 770px) {
    margin-top: 15px;
    margin-left: auto;
  }
}
.mm-icon-wrapper {
  @media (max-width: 570px) {
  }
}

.mm-input-main-wrapper {
  @media (max-width: 570px) {
    display: flex;
    width: 100%;
  }
}

.mm-form {
  @media (max-width: 570px) {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  }
}

.mm-predictions-header {
  font-weight: 800;
  font-size: 32px;
  color: $black;
}

.mm-card-date {
  font-weight: 400;
  font-size: 16px;
  color: $fadedGrey;
  margin: 25px 0px;
}

.mm-card-title {
  font-weight: 800;
  font-size: 24px;
  color: $chineseBlack;
}

@media (max-width: 1200px) {
  .march-madness-navbar {
    display: none;
  }
}

.march-madness-navbar {
  justify-content: flex-start !important;
}

.Value-Finder-title-mobile-hide {
  display: block;
}

.Value-Finder-title-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .GameCardWrapper .GameCard .GameCard__team--chart {
    font-size: 60px;
  }
}

@media (max-width: 575px) {
  .Value-Finder-title-mobile-hide {
    display: none !important;
  }

  .Value-Finder-title-mobile {
    display: block;

    p {
      text-align: center;
    }
    h2 {
      font-size: 32px !important;
    }
  }

  .header-primary {
    padding-bottom: 0px;
  }

  .MiscStats.Value-Finder .header-secondary {
    padding-right: 30px;
    margin-top: 0px;

    .container-fluid .row {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }

    .DropdownHeader {
      width: 100% !important;
    }
  }

  .playerprops-mobile-view {
  }
}

.mm-best-bets-tag {
  font-weight: 700;
  font-size: 16px;
  background-color: $persianRed;
  color: $white;
  padding: 4px 6px;
  border-radius: 6px;
}

.mm-booking-logo {
  max-width: 120px !important;
  margin-left: 10px;
  width: auto;
  height: 30px;
}

#VFLogo {
  height: 110px;
  width: 110px;
  margin-right: 20px;
}

.mm-email-submit-col {
  justify-content: space-between;
  .mm-email-submit-input {
    width: 70%;
  }

  @media (max-width: 1474px) {
    .mm-email-submit-input {
      width: 50%;
    }
  }

  @media (max-width: 470px) {
    justify-content: center;
    .mm-email-submit-input {
      width: 100%;
    }
    .mm-email-submit-input-btn {
      margin-top: 10px;
    }
  }
}
