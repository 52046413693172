.customModal {
  @media (min-width: #{$md}) {
    min-width: 95vw !important;
    max-width: 1200px !important;
    border-radius: 10px;
  }
}

.Modal-Content {
  .heading {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 10px;
    font-size: 1.5rem;
  }
  .border-bottom-lightgray {
    @media (min-width: #{$md}) {
      border-bottom: 2px solid lightgray;
    }
  }

  .border-right-lightgray {
    @media (min-width: #{$md}) {
      border-right: 2px solid lightgray;
    }
  }
}

.modal-popup.list-description {
  li {
    grid-template-columns: 75px 1fr;
    font-size: 0.85rem;
    min-height: 40px;
  }

  span.rank {
    font-size: 1.25rem !important;
  }
}

.modal {
  backdrop-filter: blur(5px);
}
.league-modal-1 {
  width: 40vw;
  max-width: 600px;
  min-width: 300px;
  border-radius: 10px;
  min-height: 243px;

  h2 {
    font-family: "Poppins", sans-serif;
    color: "$trout";
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .info {
    background-color: $bronze;
    padding: 0.5rem;
    border-radius: 10px;
    font-weight: normal;
    color: $white;
    img {
      margin-right: 10px;
      width: 25px;
    }
  }

  .card {
    border: none;

    label {
      box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
      border: none;
      border-radius: 10px;
      width: 100%;

      &:hover {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }

    input[type="checkbox"] {
      display: none;
    }
    .label {
      display: flex;
      align-items: center;
      padding: 0;
      height: 200px;
      width: 100%;
      padding: 1rem;
      border: 6px solid transparent;

      @media (max-width: 767px) {
        height: 150px;
      }
    }
    input[type="checkbox"]:checked + .label {
      border-radius: 10px;
      box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.1);
      border: 6px solid $bronze;
    }
  }
}

.playType-modal {
  max-width: 1200px !important;
  // min-height: 500px;
  border-radius: 10px;
}

.league-modal {
  border-radius: 10px;
  min-height: 243px;
  max-width: 450px !important;
}
.league-modal .btn {
  position: absolute;
  right: 11px;
  bottom: 18px;
  border-radius: 10px;
}

#rdp-modal {
  position: absolute;
  flex-shrink: inherit;
  width: auto;
  padding: 0;
  margin: 0;
  top: 70%;
  width: 650px;
  height: 470px;

  @include sm {
    width: 340px;
    padding: 0 !important;
  }
}

.mmtModal {
  .closeBtn {
    position: absolute;
    top: 30px;
    right: 30px;
    font-weight: 700;
    font-size: 22px;
  }

  h3 {
    margin-top: 56px;
    margin-bottom: 25px;
    text-align: center;
  }
  p {
    text-align: center;
  }

  .btns {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    button {
      padding: 16px 46px;
    }
  }
}
.sub-cancel-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  .cancel-modal {
    width: 50%;
    position: absolute;
    background-color: $white !important;
    padding: 40px;

    .radio-label {
      display: flex;
      align-items: left;
      margin-bottom: 20px;
      padding: 1.5rem;
      background: $linen;
      border: 1px solid $orange;
      border-radius: 5px;
      font-size: 18px;

      input {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

@mixin sub-cancel-modal {
  @media (min-width: #{$md}) {
    padding-right: 3.5vw;
    padding-left: 3.5vw;
    margin: auto;

    .auth-buttons-container {
      padding-right: 0;
      padding-left: 0;
      margin: 0;
    }
  }
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.content-container {
  position: relative;
  z-index: 1;
  width: 99vw;
  height: 100%;
  background-color: black;
  .live-iframe {
    width: 100%;
    height: 100%;
    min-height: 90rem;
    border: 0;
    @include sm {
      min-height: 180rem;
    }
    @media screen and (max-width: 767px) {
      min-height: 155rem;
    }
  }
}
