* {
  box-sizing: border-box;
}
html {
  @media (min-width: #{$md}) {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: $antiFlashWhite;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $orange;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $darkbgcolor;
    }
  }
}
body {
  font-size: $fontSize;
  font-family: $fontFamily;
  color: $textColor;
  line-height: 1.5;
  background-color: $bgcolor;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

p {
  font-family: $fontFamily;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

ol {
  list-style-position: inside;
}

ol:after,
ul:after {
  content: "";
  display: block;
  line-height: 0;
  clear: both;
  overflow: hidden;
  height: 0;
}

pre {
  border-left: 10px solid #ccc;
  max-width: 500px;
}

/* <<< Headings >>> */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontFamily;
  font-weight: 700;
  color: $black;
  line-height: normal;
}

h3 {
  font-size: 24px;
  @include lg {
    font-size: 22px;
  }
  @include md {
    font-size: 20px;
  }
}
h4 {
  font-size: 20px;
  @include lg {
    font-size: 18px;
  }
  @include md {
    font-size: 16px;
  }
}
h5 {
  font-size: 18px;
  @include lg {
    font-size: 16px;
  }
  @include md {
    font-size: 14px;
  }
}
h6 {
  font-size: 16px;
}

textarea {
  min-height: 285px;
  padding: 0 23px;
  &.form-control {
    padding-top: 12px;
    resize: none;
  }
}

iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: none;
  // body .main-svg {
  //   background-color:$titanWhite !important;
  // }
}

/* --- WP-Image Hack --- */
img {
  border: 0;
  // height: auto;
  max-width: 100%;
  &.alignleft {
    float: left;
    margin: 15px 15px 15px 0;
  }

  &.aligncenter {
    margin: 15px auto;
    float: none;
  }
  &.alignright {
    float: right;
    margin: 15px 0 15px 15px;
  }
}

/* --- anchor --- */
a {
  color: $blue;
  @include transition(0.5s);
  &:hover {
    color: $orange;
    text-decoration: none;
  }
}

button:focus {
  outline: none;
}

/* --- blockquote --- */
blockquote {
  margin: 12px 0 12px 20px;
  border-left: 10px solid $black;
  position: relative;
  padding: 30px 70px;
  text-align: center;
  &:before {
    top: 0;
    left: 0;
    content: "\201C";
  }
  &:after {
    bottom: 0;
    right: 0;
    content: "\201D";
  }
  &:before,
  &:after {
    position: absolute;
    width: 60px;
    height: 60px;
    font-size: 80px;
    @include font-awesome;
    line-height: 1;
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.5);
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.5);
}

.form-control {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 500;
  color: $textColor;
  width: 100%;
  height: 42px;
  @include appearance;
  @include border-radius(20px);
  -webkit-background-clip: padding-box;
  @include transition(0.5s);
  border-color: $silverChalice;
  background-color: $white;
  &:focus {
    border: 1px solid $silverChalice;
    outline: 0;
    @include box-shadow(none);
  }
}

.custom-select {
  padding: 0 0 0 15px;
  background: $white url("../../src/assets/images/select-arrow.png") right center no-repeat;
  cursor: pointer;
  @include appearance;
  border-color: $silverChalice;
  text-overflow: "";
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: 0;
    border-color: $silverChalice;
    background: $white url("../../src/assets/images/select-arrow.png") right center no-repeat;
    @include appearance;
    text-overflow: "";
    @include box-shadow(none);
  }
}

/* #label */
label {
  cursor: pointer;
}

/* #Input Radio  */
input[type="radio"] {
  background-color: rgba(34, 68, 127, 0.5);
  @include border-radius(30px);
  display: none;
  + span::before {
    display: inline-block;
    margin: -4px 4px 0 0;
    vertical-align: middle;
    width: 32px;
    height: 27px;
    content: "";
    background: url("../../src/assets/images/radio-icn.png") center center no-repeat;
    background-size: 25px 20px;
    cursor: pointer;
  }
  &:checked + span::before {
    content: "";
    background: url("../../src/assets/images/radio-icn-active.png") center center no-repeat;
    background-size: 25px 20px;
  }
  &:disabled + span::before {
    content: "";
    background: url("../../src/assets/images/radio-icn.png") center center no-repeat;
    background-size: 25px 20px;
    cursor: not-allowed;
  }
}

/* #Input Checkbox  */
input[type="checkbox"] {
  @include appearance;
  width: 21px;
  height: 21px;
  border: 1px solid $philippineSilver;
  outline: 0;
  line-height: 21px;
  margin: 0;
  position: relative;
  color: $philippineSilver;
  cursor: pointer;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    font-size: 12px;
    line-height: 21px;
    text-align: center;
    @include font-awesome;
    content: "";
  }
  &:checked::before {
    content: "\f00c";
  }
  &:disabled::before {
    content: "";
    cursor: not-allowed;
  }
  + span {
    font-size: 14px;
    font-weight: 500;
    color: $darkCharcoal;
  }
}

/* --- header --- */
.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: $bgcolor;
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0));
  transition: all 0.5s;
  &.fixed-header {
    // position: fixed;
    top: 0;
    width: 100%;
    background-color: $bgcolor;
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.3));
    transition: all 0.5s;
  }
  .logininfo {
    color: $white;
    list-style: none;
    margin: 0;
    padding: 0;
    @include sm {
      margin-top: 15px;
    }
    li {
      display: inline-block;
      color: $white;
      font-size: 1.1em;
      vertical-align: middle;
      margin-right: 20px;
      &:after {
        // content: "|";
        color: $white;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .btn {
        background-color: $orange;
        color: $white;
        padding: 5px 25px;
        min-width: 100px;
      }
    }
    @media (max-width: 991px) {
      li {
        width: 100%;

        a {
          width: 100%;
          padding: 13px 20px !important;
        }
      }
      .adaptive-dropdown {
        display: none;
      }
      .adaptive-dropdown-mobile {
        display: block;

        .custom-select {
          background: $white url("../../src/assets/images/select-arrow1.png") right center no-repeat !important;
        }
        .border-color {
          border: none !important;
        }
      }
    }
  }
  .topbar-warning {
    background-color: chocolate;
    color: $white;
    padding: 0.5rem 0rem;
    p {
      margin: 0px;
      color: $white;
    }
    a {
      color: $white;
      font-weight: bold;
      text-decoration: "underline";
    }
    .container-fluid {
      @media (max-width: 1159px) {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
      .dukescroe {
        @media (min-width: 1200px) and (max-width: 1400px) {
          width: auto;
        }

        @include container-fluid-lg;
      }
    }
  }
  .topbar {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $black;
    height: auto;
    @include transition(0.7s);
    a {
      font-size: 14px;
      color: $white;
      @include sm {
        font-size: 12px;
      }
      .fa {
        font-size: 18px;
        margin-right: 5px;
      }
    }

    .logininfo {
      li:nth-child(1) {
        width: 200px;
      }
    }

    .container-fluid {
      @media (max-width: 1159px) {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
      .dukescroe {
        @media (min-width: 1200px) and (max-width: 1400px) {
          width: auto;
        }

        @include container-fluid-lg;
      }
    }
  }
  .Headerside {
    background-color: $chineseBlack;
    padding-top: 30px;
    // padding-bottom: 30px;
    @include transition(0.5s);
    @include lg {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .site-logo {
      padding-bottom: 33px;
      a {
        img {
          @include transition(0.3s);
          @include lg {
            max-width: 200px;
          }
        }
      }

      @include xxl2 {
        max-width: 225px;
      }
    }

    .dropdowns-container {
      display: flex;
      flex-direction: row;
      padding-bottom: 14px;

      .login-button {
        background-color: transparent;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $white;
      }

      // .signup-button {
      //   background-color: $orange;
      //   color: $white;
      // }
    }

    .search {
      flex: 0 0 100%;
      justify-content: flex-end;
      display: flex;
      margin-bottom: 25px;
      @include transition(0.3s);
      @include lg {
        display: block !important;
        padding-left: 15px;
        padding-right: 15px;
      }
      .box {
        position: relative;
        display: inline-block;
        margin-right: 21px;
        &:last-child {
          margin-right: 0;
        }
        .form-control {
          padding-left: 15px;
          padding-right: 50px;
          font-weight: 400;
          font-size: 14px;
          max-width: 295px;
          width: 100%;
        }
        .submit {
          padding: 0 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          img {
            max-width: 20px;
          }
          &:focus {
            outline: 0;
            @include box-shadow(none);
          }
        }
      }
    }

    .container-fluid {
      @media (max-width: 1159px) {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
      .dukescroe {
        @media (min-width: 1200px) and (max-width: 1400px) {
          width: auto;
        }

        @include container-fluid-lg;
      }
    }
  }
}

/* --- Navigation --- */
.sqbets-nav {
  background-color: $white !important;
  border-bottom: 1px solid gainsboro;
  .navbar-logo-and-menu {
    min-width: 1.4rem;
    .logo.icon {
      display: none;
    }
    @media (min-width: 1200px) {
      margin-right: 2rem;
    }
    @media (max-width: 1200px) {
      .navbar-brand {
        margin-right: 0 !important;
      }
    }
    @media (max-width: 1200px) {
      .navbar-brand {
        margin-right: 0 !important;
      }
    }
    @media (min-width: 992px) and (max-width: 1080px) {
      .logo.main {
        display: none;
      }
      .logo.icon {
        display: block;
      }
    }
  }
  .navbar-nav .nav-link {
    color: $black;
    font-weight: 700;
    &:hover {
      color: $orange;
    }
  }
  .dropdown-menu {
    top: 98%;
  }
  .mobile-menu-dropdown {
    .dropdown-menu {
      position: absolute;
      top: 100%;
    }
  }
  .menu-title-strong,
  #profile-nav-dropdown {
    font-size: 16px;
  }
  [aria-labelledby="profile-nav-dropdown"] {
    left: auto !important;
    right: 0;
  }
  #responsive-navbar-nav {
    &::after {
      display: none !important;
    }
    .nav-item.dropdown.main {
      &:hover > .dropdown-menu {
        display: block;
      }

      .dropdown-menu {
        margin-top: 0px;
        .dropdown-item:hover {
          background: $orange;
          // color: $white;
        }
      }
    }
    [id^="premium-tools-nav-dropdown-"] {
      color: $orange;
      margin-left: 1rem;
    }
    [aria-labelledby^="premium-tools-nav-dropdown-"] {
      left: 100%;
      top: -28%;
    }
  }
}
// old navbar code
// .navbar {
//   padding: 0;
//   justify-content: space-between;

//   /* <<< Navigation Mobile open button >>> */

//   .nav-item {
//     .nav-sublink {
//       // border: 4px solid $trout;
//       top: 40px;
//       &::before {
//         content: "";
//         top: -6px;
//         left: 0;
//         right: 0;

//         height: 12px;
//         width: 12px;
//         margin: auto;
//         background-color: $white;
//         // border: 4px solid $trout;

//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
//         transform: rotate(45deg);
//         display: block;
//         position: absolute;
//       }

//       .active-sublink a {
//         background-color: $lightGrey;
//         color: $chineseBlack;
//         border-radius: 8px;
//       }

//       @media (min-width: 768px) {
//         min-width: 220px;
//         position: absolute;
//         width: 100%;
//         display: none;
//         padding-top: 1.5rem;

//         padding: 0;
//         list-style: none;
//         margin: auto;
//         left: -30%;

//         text-align: left;

//         background-color: transparent !important;
//         box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

//         border-radius: 12px;

//         -webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

//         animation: fade-in-bottom 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;

//         li {
//           border-bottom: 0px solid $white;
//           font-weight: 400;
//           font-size: 14px;
//           background-color: $white;
//           padding: 5px 16px;

//           a {
//             color: $black;
//             border-radius: 12px;
//             flex-wrap: nowrap;

//             &:hover {
//               background-color: $white;
//               color: $orange;
//             }
//           }

//           &:first-child {
//             border-top-left-radius: 12px;
//             border-top-right-radius: 12px;
//           }

//           &:last-child {
//             border-bottom-left-radius: 12px;
//             border-bottom-right-radius: 12px;
//           }

//           &:hover {
//             a {
//               background-color: $white;
//               color: $orange;
//             }
//           }
//         }
//       }

//       @media (max-width: 766px) {
//         padding-left: 0px;
//         border: none;
//       }
//       @include xl {
//         list-style: none;
//         &::before {
//           display: none;
//         }
//       }
//     }
//   }
//   .navbar-toggler {
//     padding: 0;
//     position: absolute;
//     top: 0;
//     right: 0;
//     margin: 0;
//     z-index: 9;
//     @include box-shadow(none);
//     outline: 0;
//     &:after {
//       position: fixed;
//       top: 0;
//       right: 0;
//       width: 100vw;
//       height: 100vh;
//       content: "";
//       display: block;
//       z-index: 1;
//     }
//     .btn_txt {
//       color: $white;
//       float: left;
//     }
//     .menu_icon {
//       margin: 0;
//       padding-top: 1px;
//       float: left;
//       font-size: 22px;
//       width: 26px;
//       height: 22px;
//       position: relative;
//       @include transition(0.5s);
//       cursor: pointer;
//       .icon-bar {
//         display: block;
//         position: absolute;
//         opacity: 1;
//         width: 26px;
//         height: 2px;
//         @include border-radius(3px);
//         background-color: $gray;
//         margin: 0 auto;
//         left: 0;
//         @include transition(0.5s);
//         &:nth-child(1) {
//           top: 8px;
//           transform: rotate(45deg);
//         }
//         &:nth-child(2) {
//           top: 8px;
//           opacity: 0;
//           left: -20px;
//         }
//         &:nth-child(3) {
//           top: 8px;
//           transform: rotate(-45deg);
//         }
//       }
//     }
//     &.collapsed {
//       &:after {
//         display: none;
//       }
//       .icon-bar {
//         transform: rotate(0deg);
//         @include transition(0.5s);
//         background-color: $gray;
//         &:nth-child(1) {
//           top: 0;
//           transform: rotate(0);
//         }
//         &:nth-child(2) {
//           top: 8px;
//           left: 0;
//           opacity: 1;
//         }
//         &:nth-child(3) {
//           top: 16px;
//           transform: rotate(0);
//         }
//       }
//     }
//   }
//   .navbar-collapse {
//     flex-wrap: wrap;
//     @include xl {
//       padding-top: 0;
//       padding-bottom: 15px;
//       position: fixed;
//       top: 69px;
//       left: -100vw;
//       bottom: 0;
//       z-index: 9;
//       background-color: $chineseBlack;
//       display: block;
//       height: 100vh;
//       height: calc(100% - 75px);
//       width: 100vw;
//       overflow: auto;
//       @include transition(0.3s);
//       &.show,
//       &.collapsing {
//         left: 0;
//       }
//       .sitelogo {
//         padding: 15px;
//         background-color: $white;
//       }
//     }
//     @include xl {
//       padding: 0px 40px;
//     }
//   }
//   .navbar-nav {
//     flex: 0 0 100%;
//     justify-content: center;
//     a.nav-link {
//       text-transform: capitalize;
//       padding: 0;
//       margin: 0;
//       color: $manatee;
//       text-transform: inherit;
//       @include xl {
//         color: $white;
//         padding: 10px 15px;
//       }
//     }

//     > li {
//       // padding: 0.5rem 1.25rem;
//       padding-bottom: 28px;
//       font-size: 16px;
//       font-weight: 600;
//       @include xl {
//         padding-right: 0;
//         text-align: left;
//         border-bottom: 1px solid $white;
//       }

//       @include xxl2 {
//         padding-right: 25px;
//         font-size: 14px;
//       }

//       @include md {
//         padding-left: 0px;
//         padding-right: 0px;
//       }
//       &:last-child {
//         padding-right: 0;
//         border-bottom: 0;
//       }
//       &:hover > a,
//       &.active > a.active,
//       &.current-menu-ancestor > a.dropdown-toggle {
//         background-color: transparent;
//         position: relative;
//         color: $white;
//         outline: 0;
//         @include xl {
//           background-color: $white;
//         }

//         @include xl {
//           background-color: $chineseBlack;
//           color: $orange;

//           &:before {
//             display: none;
//           }
//         }

//         &:before {
//           content: "";
//           position: absolute;
//           width: 100%;
//           height: 5px;
//           background-color: $orange;
//           bottom: -37px;
//         }

//         svg {
//           stroke: $white;
//           transform: rotate(180deg);
//         }
//       }

//       svg {
//         stroke: $manatee;
//       }
//       @include xl {
//         border-bottom: none;
//         padding-bottom: 10px;
//         font-size: 20px;
//       }
//     }
//     ul > li {
//       padding: 0;
//       float: left;
//       width: 100%;
//       > a {
//         border-bottom: 0;
//         color: $white;
//         padding: 10px 19px 13px;
//         display: block;
//         @include xl {
//           padding: 10px 19px 13px 25px;
//         }
//       }
//       &:hover > a,
//       &.active > a.active,
//       &.current-menu-ancestor > a.dropdown-toggle {
//         color: $white;
//         background-color: $catalinaBlue;
//       }
//     }

//     .dropdown-menu {
//       border: none;
//       border-radius: 0;
//       padding: 15px 0 0;
//       font-size: 18px;
//       width: 191px;
//       @include border-radius(5px 5px 0 0);
//       background-color: transparent !important;
//       z-index: 9;
//       @include xl {
//         width: 100%;
//       }
//       &.show > li > a {
//         color: $black;
//         background-color: transparent;
//         &:focus,
//         &:hover {
//           color: $white;
//           background-color: $catalinaBlue;
//         }
//       }
//       .dropdown-item:after,
//       .nav-link:after {
//         padding: 5px 4px 0 0;
//         content: "\f054";
//         font-family: fontawesome;
//         vertical-align: middle;
//         font-size: 12px;
//         float: right;
//         width: auto;
//         height: auto;
//         @include md {
//           display: none;
//         }
//       }
//       .content {
//         background-color: $white;
//         overflow: hidden;
//         @include border-radius(5px 5px 0 0);
//         @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
//         .title {
//           padding: 10px 15px;
//           font-size: 14px;
//           color: $darkGrey;
//           background-color: $antiFlashWhite;
//         }
//         .text {
//           padding: 15px;
//           font-size: 12px;
//           font-weight: 400;
//           line-height: 22px;
//         }
//       }
//     }

//     .caret {
//       display: inline-block;
//       display: none;
//       @include lg {
//         display: inline-block;
//       }
//       &:before {
//         display: inline-block;
//         height: 25px;
//         width: 25px;
//         line-height: 25px;
//         content: "\f078";
//         color: $white;
//         font-family: fontawesome;
//         text-align: center;
//         border-radius: 5px;
//       }
//       &.caret-open:before {
//         content: "\f077";
//       }
//     }
//     @media (min-width: 1441px) {
//       justify-content: space-evenly;
//     }
//   }
// }
/* footer */
.footer {
  overflow: hidden;
  padding-top: 35px;
  padding-bottom: 30px;
  background-color: $chineseBlack;
  z-index: 100;
  position: relative;
  @media (max-width: #{$sm}) {
    display: flex;
    flex-direction: column;
    padding: 40px 22px;
  }
  .middleFooterBlock {
    display: flex;
    justify-content: center;

    .footerColumn {
      text-align: left;
      @include md {
        text-align: center;
      }

      p {
        color: $white;
      }

      .columnHeader {
        font-family: var(--font-inter);
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        color: $orange;
        margin-bottom: 26px;
      }

      .question {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 4px;
        @include xl {
          font-size: 14px;
        }
      }

      .answer p {
        font-size: 14px;
        font-weight: 400;

        @include xl {
          font-size: 12px;
        }
      }

      @include md {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .policyWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 42px;

      @include md {
        flex-direction: column;
        text-align: center;
      }
    }
  }

  .bottomFooterBlock {
    margin-top: 30px;
    p {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }
  }
  .footerDisclaimer {
    color: $white;
    font-size: 12px;
    width: 456px;
    text-align: center;

    @include lg {
      width: auto;
    }
  }
  .mobileFooterBtn {
    @media (max-width: #{$sm}) {
      display: flex;
      // width: 21.5rem;
      margin: 24px auto auto auto;
      justify-content: space-between;
    }
  }
  .footerSocialIcons {
    display: flex;
    align-items: center;
    width: 250px;
    justify-content: space-between;

    & .footerBtn {
      font-size: 14px;
    }
  }
  .SocialIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: 50%;

    @include xl {
      height: 24px;
      width: 24px;
    }
  }
  .SocialIconImg {
    height: 34px;
    width: 34px;

    @include xl {
      height: 24px;
      width: 24px;
    }
  }
  .footerLink {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
  }
  .fd-row {
    @media (max-width: #{$sm}) {
      flex-direction: row;
    }
  }
  .sm-init {
    display: none;
    @media (max-width: #{$sm}) {
      display: flex;
      height: 71px;
      justify-content: space-between;
    }
  }
  .footerBtn {
    font-family: var(--font-inter);
    color: $white;
    margin-bottom: 0px;
    font-size: 14px;

    @media (max-width: #{$lg}) {
      font-size: 12px;
    }
  }
  .bottomFooterBtn {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

    p {
      color: $white;
      text-align: left;
      font-size: 12px;
    }
    @media (max-width: #{$sm}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .bs-init {
    @media (max-width: #{$sm}) {
      display: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 14px;
      display: inline-block;
      &:after {
        content: "-";
        color: $white;
        display: inline-block;
        margin-left: 10px;
        margin-right: 7px;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      a {
        color: $white;
        display: inline-block;
        &:hover {
          color: $orange;
        }
      }
    }
  }
}

/* --- Global Accordion --- */
.accHeading {
  display: block;
  cursor: pointer;
  .icn:after {
    @include font-awesome;
    content: "\f107";
  }
}
.accHeading.collapsed .icn:after {
  content: "\f106";
}

/* --- Global Container --- */
.container {
  max-width: 1280px;
  // width: auto;
}

.container:after,
.row:after,
.clear {
  content: "";
  display: block;
  line-height: 0;
  clear: both;
  overflow: hidden;
  height: 0;
}

.row.no-after:after {
  content: none;
}

.Calendar {
  padding: 1rem 0rem;

  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .slick-slide {
    margin: 0 10;
    @media (max-width: 820px) {
      margin: 0 -10;
    }
  }
  .slick-list {
    margin: 0 -60px;
    @media (max-width: 820px) {
      margin: 0 -50;
    }
  }
  .date {
    margin: auto;
    float: none;
    outline: none;
    border: 1px solid $chineseBlack;
    display: block;
    background-color: transparent;
    width: 84px;
    height: 84px;
    color: $black;
    border-radius: 12px;
    font-weight: bold;
    position: relative;
    text-align: center;
    transition: all 0.25s ease-in-out;
    &:hover {
      border-color: $arsenic;
      color: $orange;
      background-color: $orange;
    }

    .dateNum {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .day {
      display: inline-block;
      padding-top: 10px;
      font-weight: 400;
      font-size: 16px;
      width: 100%;
    }

    &:hover {
      border: 1px solid $orange;
      background-color: $cornSilk;
      color: $orange;
    }
  }

  .is-active {
    background-color: $cornSilk;
    border: 1px solid $orange;
    color: $orange;
  }

  // .slick-list {
  //   overflow: visible !important;
  // }

  .slick-arrow {
    display: none !important;
  }
}
@mixin tooltipConMixin {
  position: absolute;
  bottom: 100%;
  left: 0;
  color: $darkGrey;
  padding: 0 15px 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  min-width: 200px;
  text-align: left;
  display: none;

  background-color: $white;
  overflow-y: auto;
  @include border-radius(5px 5px 0 0);
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
  b {
    font-weight: 600;
    margin-bottom: 5px;
    padding: 10px 15px;
    font-size: 14px;
    color: $darkGrey;
    background-color: $antiFlashWhite;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 15px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $antiFlashWhite;
  }

  &::-webkit-scrollbar-thumb {
    background: $taupeGray;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $davysGrey;
  }
}

/* Table */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  overflow: visible;
  @media (max-width: 991px) {
    overflow: scroll;
  }
  &.overflow-auto {
    overflow-x: auto;
    .sticky-th-2 {
      top: 0;
    }
  }
  .load-m {
    color: $bronze;
    cursor: pointer;
    font-size: 1rem;
  }
  .table {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 56px;
      z-index: 2;

      @media (max-width: 768px) {
        top: 0;
      }
    }
    tr th,
    tr td {
      font-family: $fontFamily;
      border-right: 0;
      border-left: 0;
      padding: 0.4rem 0.3rem;
    }

    tr,
    .thead {
      th {
        position: relative;
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        color: $white;
        background-color: $chineseBlack;

        &:first-child {
          border-top-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
        }
        .fa {
          position: absolute;
          top: 50%;
          right: 10px;
          font-size: 12px;
          margin-left: 5px;
          display: inline-block;
          vertical-align: top;
          margin-top: -7px;
        }
        .rank {
          transition: opacity 0.25s ease-in-out;
          padding: 100px;
          position: absolute;
          bottom: 100%;
          background-color: rgba($color: $white, $alpha: 1);
          left: 120;
          right: 0;
          @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
          @include border-radius(5px 5px 0 0);
          // border: 2px solid $orange;
          img {
            width: 100%;
            max-width: 100px;
          }
        }
        .tooltipCon {
          @include tooltipConMixin;
        }
        &:last-child {
          .tooltipCon {
            left: auto;
            right: 0;
          }
        }
        &:hover {
          .tooltipCon {
            display: block;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          background-color: $white;
        }
        &:nth-of-type(even) {
          td {
            background-color: $ghostWhite;
          }
        }
        &:last-child {
          border-bottom: 0;

          td {
            border-bottom: 0;

            &:first-child {
              border-radius: 0 0 0 24px;
            }

            &:last-child {
              border-radius: 0 0 24px 0;
            }
          }
        }
        td {
          font-weight: 400;
          &.bg-red {
            background-color: $red;
          }
          &.bg-green {
            background-color: $electricGreen;
          }
          &.bg-ivory {
            background-color: $olive;
          }
          a {
            // text-align: left;
            display: block;
          }
          img {
            max-width: 25px;
            &.scoregraph {
              max-width: 135px;

              @media (max-width: #{$xxl2}) {
                max-width: 100px;
              }
            }
          }
          @media (max-width: #{$md}) {
            padding: 0.45rem;
            font-size: 13px;
          }
        }
      }
    }
  }
  .shottypeAnalysis {
    border-collapse: separate;
    border-spacing: 0;

    & th {
      font-weight: 700 !important;

      &:first-child {
        border-top-left-radius: 12px !important;
        width: 122px;
        max-width: 96px;
      }
    }
    & td.RankCell {
      border: 0.5px solid $lightGrey !important;
      // & span {
      //   @include xl {
      //     margin-right: 6px;
      //   }
      // }
    }
  }

  .miscAnalysis {
    border-collapse: separate;
    border-spacing: 0;
    th {
      font-weight: 700 !important;
    }
    & td {
      border: 0.5px solid $lightGrey !important;
      & a {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .regressionAnalysis {
    border-collapse: separate;
    border-spacing: 0;

    & tr:nth-child(even) {
      background-color: $athensGrey;
    }

    & th {
      border-right: 1px solid $fadedGrey !important;
      width: 96px;
      font-weight: 700 !important;

      &:first-child {
        width: 113px;
      }

      @include xl {
        max-width: 60px;
      }
    }

    & td {
      border: 0.5px solid $lightGrey !important;

      & a {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  thead {
    &:hover {
      .rank {
        opacity: 0;
      }
    }
  }
}
.table-responsive.overflow-auto {
  &::-webkit-scrollbar {
    width: 4px;
  }
}
/* Partner Logo */
.PartnerLogo {
  padding-top: 30px;
  padding-bottom: 50px;
  @include md {
    padding: 0;
  }

  &.page {
    padding-top: 0px;
    h3 {
      margin-bottom: 30px;
      color: $orange;
    }
    .item {
      margin-bottom: 30px;
      @include md {
        margin-bottom: 10px;
      }
      .bdr {
        background-color: $white;
      }
    }
  }
  .row {
    @include md {
      margin-left: -5px;
      margin-right: -5px;
    }
  }
  .item {
    padding-left: 15px;
    padding-right: 15px;
    @include md {
      padding-left: 5px;
      padding-right: 5px;
    }
    @include sm {
      margin-bottom: 10px;
    }
    .bdr {
      padding: 15px;
      border: 1px solid $spanishGrey;
      width: 100%;
      height: 100%;
      @include sm {
        padding: 10px 5px;
      }
    }
  }
  .partnerlogo {
    @include lg {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  .slick-arrow {
    width: 56px;
    height: 56px;
    border: 1px solid $spanishGrey;
    @include border-radius(50%);
    background-color: $bgcolor;
    @include lg {
      width: 40px;
      height: 40px;
    }
    &:before {
      font-size: 34px;
      @include lg {
        font-size: 24px;
      }
    }
    &.slick-prev {
      left: -65px;
      @include lg {
        left: 0px;
      }
    }
    &.slick-next {
      right: -65px;
      @include lg {
        right: 0px;
      }
    }
  }
}
.GamelogList h3 {
  margin-bottom: 30px;
}

/* --- Outer --- */
#Outer {
  position: relative;
  width: 100%;
  min-height: 100%;
}

/* About list */
.Aboutlist {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $orange;
  text-align: center;
  margin-bottom: 40px;
  @include sm {
    text-align: left;
    margin-bottom: 20px;
  }
  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    z-index: 1;
    @include lg {
      top: 52px;
    }
    @include md {
      position: static;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-right: 15px;
      margin-right: 15px;
      display: inline-block;
      border-right: 1px solid $white;
      line-height: 26px;
      letter-spacing: 0.4px;
      @include lg {
        padding-right: 10px;
        margin-right: 10px;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }
      a {
        font-weight: 600;
        display: block;
        color: $white;
      }
    }
  }
  h2 {
    color: $white;
    margin-bottom: 0;
  }
  h4 {
    font-size: 42px;
    min-height: 95px;
    display: inline-flex;
    align-items: center;

    @media (max-width: #{$sm}) {
      font-size: 22px;
      min-height: 35px;
    }
  }

  img {
    max-width: 100px;
  }
}

.header-container-table {
  .sticky-th-2 {
    height: 50px;
    &:first-child {
      border-top-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
    }
  }
}
.table-space {
  padding-top: 50px;
}
.HoveredTableCell {
  background-color: rgba($color: $blue, $alpha: 0.2);
  transition: all 0.25s ease-in-out;
  &:hover {
    background-color: rgba($color: $blue, $alpha: 0.5);
    cursor: pointer;
  }
}

.team-page-single .tooltipCon {
  display: none !important;
}
.team-page-single .sticky-th-2 .rank {
  display: none !important;
}

.loading-icon {
  margin: 1rem auto;
  width: 35px;
}

.AllPlaysList {
  .form-control-range {
    width: 100%;
    padding: 0.25rem 0.25rem;
  }
}

.AllPlaysSection {
  .form-control-range {
    width: 100%;
    padding: 0.25rem 0.25rem;
  }
}

.sliderRange-dual,
.sliderRange-single {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  min-height: 50px;
  margin-top: 10px;
  span {
    position: relative;
    background-color: $chineseBlack;
    display: grid;
    align-items: center;
    height: 100%;
    border-radius: 6px;
    margin-top: -45px;
    left: -15px;
    height: 30px;
    width: 34px;
    h4 {
      color: $white;
      font-size: 14px;
      margin: 0;
    }

    &::after {
      content: " ";
      position: absolute;
      top: 25px;
      margin-top: 0px;
      left: 6px;
      border-width: 11px;
      border-style: solid;
      border-color: $chineseBlack transparent transparent transparent;
    }
  }

  .form-control-range {
    position: absolute;
    z-index: -1;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &::-webkit-slider-runnable-track {
      height: 15px;
      background-color: transparent;
      border: 2px solid $gray;
      border-radius: 10px;
      z-index: 1;
    }

    &::-webkit-slider-thumb {
      transform: translateY(-2.5px) scale(1.5);
      border: 2px solid $gray;
      z-index: 1;
    }

    &::-moz-range-track {
      height: 30px;
    }

    &:first-child {
      &::-webkit-slider-runnable-track {
        z-index: 2;
      }
    }
    &:last-child {
      &::-webkit-slider-runnable-track {
        z-index: 3;
      }
    }
  }
}

/* Article Single */
.ArticleSingle {
  padding-bottom: 30px;
  .img {
    margin-bottom: 30px;
  }
  p {
    font-size: 18px;
    color: $black;
    font-family: "Poppins", sans-serif;
  }
}

/* Game Score */
.GameScore {
  padding-bottom: 30px;
  table {
    min-width: 710px;
    tr {
      td {
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: #{$md}) {
  .w-100-on-mobile {
    width: 100%;
  }
  .twitter-tweet {
    width: 100% !important;
    margin: auto;
  }
}

// Date Picker Overrides

.react-datepicker {
  border: none !important;

  .react-datepicker__header {
    background: $orange !important;
    font-family: "Poppins", sans-serif;
    .react-datepicker__current-month {
      color: $white;
    }

    .react-datepicker__day-name {
      color: $white;
    }
  }

  .react-datepicker__month-container {
    border: none;
    outline: none;
    box-shadow: 10px 10px 19px -1px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 10px 10px 19px -1px rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 10px 10px 19px -1px rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.35s ease-in;

    &:hover {
      box-shadow: 10px 10px 19px -8px rgba(0, 0, 0, 0.19);
      -webkit-box-shadow: 10px 10px 19px -8px rgba(0, 0, 0, 0.19);
      -moz-box-shadow: 10px 10px 19px -8px rgba(0, 0, 0, 0.19);
    }
  }

  .react-datepicker__day {
    font-family: "Poppins", sans-serif;
  }

  .react-datepicker__day--selected {
    background: $darkbgcolor;
  }
}
.Predictions {
  .hero {
    background-color: $black;
    @media (min-width: #{$md}) {
      h4 {
        font-size: 42px;
        min-height: 95px;
        display: inline-flex;
        align-items: center;
        background-color: $black;
      }
    }

    @media (max-width: #{$sm}) {
      h4 {
        min-height: 35px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
  .herro {
    //background-color: $white;
    @media (min-width: #{$md}) {
      h4 {
        font-size: 42px;
        min-height: 60px;
        display: inline-flex;
        align-items: center;
        //background-color: $white;
      }
    }
  }
  .heroo {
    //background-color: $white;
    @media (min-width: #{$md}) {
      h4 {
        font-size: 24px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
        //background-color: $white;
      }
    }
  }
}

.PlayLog {
  h2 {
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
  }

  form {
    text-align: left;
  }

  .custom-select {
    font-size: 24px;
    height: 45px;
    border-radius: 0px;
    @media (min-width: $lg) {
      max-width: 250px;
    }
  }
  .btn-primary {
    margin-left: 15px;
    @media (max-width: #{$md}) {
      margin: 15px 0px;
      margin-right: 15px;
    }
  }

  .sliderRange-dual {
    @media (max-width: #{$md}) {
      width: 90%;
      margin: 15px auto;
    }
  }
}

#ContentBox {
  scroll-behavior: smooth;
  background-color: $lotion;
  min-height: auto !important;
}

.clearfix:before,
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}
.SectionGameCard {
  background-color: $bgcolor;
  min-height: 60vh;
  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
}

.customOverlay {
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

.accordion-item {
  border-bottom: 1px solid $lightGrey;
  padding: 32px 0;

  &:first-child {
    border-top: 1px solid $lightGrey;
  }

  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    color: $chineseBlack;
    cursor: pointer;
  }

  .close-icon {
    position: relative;
    width: 36px;
    height: 36px;
    background: $white;
    box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
    border-radius: 18px;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 14px;
      height: 2px;
      background-color: $chineseBlack;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 2px;
      height: 14px;
      background-color: $chineseBlack;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .active-accordion {
    &:after {
      height: 0px;
    }
  }

  .accordion-content-active {
    margin-top: 22px;
    height: 100%;
    overflow: hidden;
    transition: height 1.5s 0.5s;
    font-size: 16px;
    font-weight: 400;
    color: $chineseBlack;
  }

  .accordion-content {
    height: 0px;
    overflow: hidden;
  }
}
.Matchup-item-slider {
  width: 210px;
  background: $white;
  box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
  border-radius: 12px;
  padding: 16px;

  .team-logos-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      color: $chineseBlack;
    }
  }

  .team-logo {
    width: 24px;
    height: 24px;
    background: $white;
    border: 1px solid $lightGrey;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;

    img {
      width: 12px;
    }
  }

  .time {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $chineseBlack;
    margin-bottom: 13px;
    width: 100%;
    justify-content: center;
  }

  .teams {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: $chineseBlack;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.circle_animation {
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  transition: all 0.35s ease-in-out;
}

.stretch-column {
  min-width: 180px !important;
  width: 200px !important;
  margin-left: 20px;
}

.mm-stretch-column {
  min-width: 280px !important;
  width: 200px !important;
  margin-left: 20px;
}

.mm-extra-stretch-column {
  min-width: 380px !important;
  width: 300px !important;
  margin-left: 20px;
  td {
    padding: 20px 0;
    img {
      margin: 200px 0;
    }
  }
}

.mm-extra-large-stretch-column {
  min-width: 480px !important;
  width: 400px !important;
  margin-left: 20px;
  td {
    padding: 20px 0;
    img {
      margin: 200px 0;
    }
  }
}

// Scrollable Table to be only enabled on Mobile Devices

.Scoreside .dukescroe .box {
  margin: 7px 0px;
}

.pagination {
  padding: 0 !important;
}

.year-filter {
  text-align: center !important;
  ul {
    li {
      font-size: 18px !important;
      margin: auto;
    }
  }
}

// .outer {
//   // AQUI
//   overflow-x: scroll;

//   .inner {
//     .table-responsive {
//       display: inline-block;
//       padding-top: 0;
//       transform: none;
//     }
//     .table {
//       // table-layout: fixed;

//       thead {
//         // AQUI
//         .sticky-th-1,
//         .sticky-th-2 {
//           position: sticky;
//           top: 0;
//           z-index: 8;
//           white-space: nowrap;
//           font-size: 12px;
//           min-width: fit-content;
//           width: fit-content;
//         }
//         .hard_left,
//         .next_left {
//           z-index: 9;
//           border: none;

//           &::before {
//             padding: 0;
//             margin: 0;
//             position: absolute;
//             content: "";
//             height: 20px;
//             top: 0;
//             height: 100%;
//             width: 3px;
//             left: -1px;
//             display: block;
//             background-color: $chineseBlack;
//           }
//         }

//         th {
//           border: none;
//           min-width: 40px;
//           padding: 0.35rem;
//           .fa {
//             display: none;
//           }

//           .rank {
//             display: none;
//           }
//         }

//         .tooltipLink {
//           display: none;
//         }
//       }

//       .hard_left {
//         left: 0px;
//       }

//       .next_left {
//         left: 60px;
//       }

//       tbody {
//         tr {
//           .hard_left {
//             position: sticky;
//             left: 0;
//             z-index: 8;
//             border: none;
//           }

//           &:nth-child(even) {
//             .next_left {
//               &::before {
//                 padding: 0;
//                 margin: 0;
//                 position: absolute;
//                 content: "";
//                 height: 20px;
//                 width: 3px;
//                 left: 0px;
//                 display: block;
//                 top: 0;
//               }
//             }
//           }

//           &:nth-child(odd) {
//             .next_left {
//               &::before {
//                 padding: 0;
//                 margin: 0;
//                 position: absolute;
//                 content: "";
//                 height: 20px;
//                 width: 3px;
//                 left: 0px;
//                 display: block;

//                 top: 0;
//               }
//             }
//           }

//           .next_left {
//             position: sticky;
//             left: 70px;
//             z-index: 8;
//             border: none;
//           }
//           td {
//             font-size: 14px;
//             // white-space: nowrap;
//             height: 60px;
//             max-width: 110px;
//             // overflow: hidden;
//             padding: 4px 3px;
//             vertical-align: middle;

//             a {
//               color: $chineseBlack;
//             }
//           }
//         }
//       }

//       .scoregraph {
//         max-width: 75px;
//       }
//     }
//   }
// }
.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    border-bottom: 1px solid$darkGreyX11;
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &--selected {
      background: $white;
      border-color: $darkGreyX11;
      color: $black;
      border-radius: 5px 5px 0 0;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px hsl(208, 99%, 50%);
      border-color: hsl(208, 99%, 50%);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: $white;
      }
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

.search-dropdown {
  border-radius: 20px 20px 0 0;
  position: absolute;
  background-color: $white;
  z-index: 1;
  width: 100%;

  .opt-list {
    position: absolute;
    width: 100%;
    background: $white;
    max-height: 200px;
    box-shadow: 0 4px 8px 0 $bronze;
    overflow-y: scroll;
  }
  img {
    width: 22px;
    height: 25px;
    margin-right: 5px;
  }
  span {
    color: $black;
    padding-left: 42px;
    text-decoration: none;
    display: block;
  }
  span:hover {
    background-color: $bronze;
    color: $white;
  }
}

.game-schedule {
  min-height: 60vh;
  padding: 5px 0 0 0;
  .text-center {
    min-height: 35px;
  }
  .text-center img {
    margin: 0;
  }
  .tdu {
    cursor: pointer;
  }
  table {
    margin-bottom: 0;
  }
  td {
    border: 1px solid;
    border-width: 0 0 0 1px;
  }
  td:hover {
    background: $silver;
  }
  td:nth-child(1),
  th:nth-child(1) {
    padding-left: 0;
    border-width: 0;
  }
  td:nth-child(2),
  th:nth-child(2) {
    display: none;
  }
  th:nth-child(4) {
    min-width: 90px;
  }
  th:nth-child(5) {
    min-width: 100px;
  }
  th:nth-child(6) {
    min-width: 61px;
  }
  .team-name {
    display: none;
  }

  .Titleside {
    border-radius: 10px 10px 0 0;
  }
  .tbl-wrap {
    max-height: 600px;
    display: block;
    overflow-y: auto;
    border: 4px solid $bronze;
    margin-bottom: 35px;
  }
  td,
  th,
  .not-found {
    font-size: 0.9rem;
    font-weight: 600;
    text-align: center;
    padding: 0;
  }
  .not-found {
    font-size: 1.6rem;
    padding: 20px;
  }
  th {
    position: sticky;
    top: -1px;
    background: $titanWhite;
    background-image: linear-gradient(45deg, $white, $bronze);
  }
  a img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  @media (min-width: 700px) {
    td,
    th {
      font-size: 1.2rem;
    }
    .team-name {
      display: inline;
    }
  }
  @media (min-width: 900px) {
    td:nth-child(2),
    th:nth-child(2) {
      display: table-cell;
    }
    .score {
      display: inline;
    }
  }
}

.table-responsive {
  @media (max-width: #{$md}) {
    padding-top: 100px;
    transform: translateY(-35px);
  }

  @media (max-width: 813px) and (orientation: landscape) {
    padding-top: 125px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding-top: 30px;
  }

  border-collapse: collapse;
}

.styled-link {
  position: relative;
  padding: 0.15rem 0.75rem;
  display: inline-block;

  &::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 2px;
    width: 90%;
    margin: auto;
    background-color: $blue;
    transition: 0.15s height ease-in-out;
  }

  &:hover {
    &::before {
      height: 5px;
    }
  }
}

.gg-check-o {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 100px;
}
.gg-check-o::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 3px;
  top: -1px;
  width: 6px;
  height: 10px;
  border-color: currentColor;
  border-width: 0 2px 2px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

.list-description {
  list-style: none;
  // border: 1px solid $philippineSilver;
  padding: 0rem;
  // margin: 2rem auto;
  margin-bottom: 2rem;
  li,
  animating {
    grid-template-columns: 1fr;
    border-bottom: 1px solid $philippineSilver;
    min-height: 50px;
    // &:last-child {
    //   border-bottom: none;
    // }
  }

  li {
    display: grid;
    align-items: center;
    grid-template-columns: 100px 1fr;
    font-size: 1.25rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    opacity: 0;
    transition: background-color 0.25s ease-in-out;

    @media (max-width: 767px) {
      font-size: 16px;
      grid-template-columns: 90px 1fr;
    }

    &:hover {
      background-color: $astral;
    }
    span {
      text-align: center;

      font-weight: 600;
      z-index: 1;
      position: relative;
      @media (max-width: 767px) {
        font-size: 16px;
        grid-template-columns: 90px 1fr;
      }
    }

    span.rank {
      text-align: left;
      font-size: 2rem;
      color: $chineseBlack;
      padding-right: 8px;
      // -webkit-text-fill-color: $chineseBlack; /* Will override color (regardless of order) */
      // -webkit-text-stroke-width: 1.5px;
      // -webkit-text-stroke-color: $trout;
      @media (max-width: 767px) {
        font-size: 26px;
      }
    }
  }
}

.loader {
  position: absolute;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  top: 50%;
  left: 0;
  right: 0;
  // bottom: 0;

  g {
    stroke-width: 2;
  }
}

.Percentile {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  .ring {
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: block;
    border: 2px solid black;
    margin: auto;
  }

  span {
    width: 100%;
  }
}
.Player {
  @include card_base();

  .card-header,
  .card-body,
  .list-group-item {
    padding: 0.75rem;
  }

  .card-header {
    min-height: 200px;
    border-bottom: none;
    display: grid;
    position: relative;
    background: $bronze;

    h2 {
      z-index: 2;
    }

    .card-title {
      display: grid;
      align-items: end;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(100px);
      -webkit-backdrop-filter: blur(100px);
      z-index: 1;
    }
  }
}

.BlogCard {
  @include card_base();
  overflow: hidden;
  margin-bottom: 2rem;
  margin: 0.5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 575px) {
    width: 100%;
    margin: auto;
  }

  // max-width: 320px;

  .BlogCard-title {
    color: $white;
  }

  .BlogCard-cover {
    min-height: 300px;
    padding: 1rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: grid;
    align-items: end;
  }

  .BlogCard-body {
    padding: 1rem;
  }
}

.Sidebar {
  padding: 1rem;
  position: sticky;
  // top: 200px;
  max-width: 90%;
  background-color: $chineseBlack;
  border-radius: 6px;
  box-shadow: 0px 0px 5px silver;
  padding: 33px 38px;

  li {
    list-style: none;
    // padding: 1rem;
    // font-size: 1.15rem;
    border-radius: 4px;
    transition: all 0.25s ease-in;
    display: flex;
    align-items: center;
    padding: 13px;
    margin-bottom: 5px;
    // margin: 0.5rem auto;

    p {
      margin: 0;
      color: $white;
      span {
        font-size: 1.5rem;
      }
    }

    &:hover {
      background-color: $orange;
      color: $white !important;

      a {
        color: $white;
      }
    }
  }

  li.active {
    background-color: $orange;
    color: $white !important;

    a {
      color: $white;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    border-radius: 36px;
    padding: 0.75rem 1rem;
    top: 15px;
    position: relative;
  }

  &.Sidebar--slider {
    button {
      opacity: 0.6;
    }
    button.active {
      opacity: 1;
      background: $bronze !important;
    }
    .slick-slide {
      margin: 0rem 0.25rem;
    }
  }
}

.SQDoctor {
  position: fixed;
  right: 4vh;
  bottom: 4vh;
  z-index: 99;

  @media (max-width: 767px) {
    right: 15px;
    bottom: 15px;
  }

  .icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $trout;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem;
    height: 4rem;
    width: 4rem;
    font-size: 3rem;
    border-radius: 50%;
    box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.224);
    -webkit-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
    opacity: 0;
    animation: fade-in 0.5s ease-in forwards;
    animation-delay: 1s;
    cursor: pointer;

    @media (max-width: 991px) {
      z-index: 100;
    }
  }

  .question {
    cursor: pointer;
    background: $white;
    padding: 1rem;
    width: 350px;
    position: absolute;
    bottom: 5rem;
    right: 0;
    border-radius: 4px;
    box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.3);
    -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation-delay: 5s;
    h5 {
      font-family: var(--font-poppins);
      font-weight: 500;
    }
    &:hover {
      background: whitesmoke;
    }

    @media (max-width: 991px) {
      width: 90vw;
      max-width: 350px;
    }
  }

  .popup {
    position: absolute;
    overflow: hidden;
    bottom: 5rem;
    padding: 1rem;

    right: 0;
    width: 350px;
    background: $white;
    height: 550px;
    min-height: 550px;
    overflow-y: scroll;
    border-radius: 4px;
    box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.224);
    -webkit-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: -2px 3px 33px 4px rgba(0, 0, 0, 0.06);

    @media (max-width: 991px) {
      bottom: -15px;
      right: -15px;
      height: 100vh;
      width: 100vw;
      padding: 1.5rem 2rem;
      padding-top: 8rem;
    }

    @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
      max-height: 600px;
      max-width: 400px;
    }
  }
}

.TeamSelectionPopup {
  overflow: hidden;
  .banner {
    position: absolute;
    height: 40%;
    background: linear-gradient(45deg, $bronze, $trout);
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    right: 0;
  }

  .SelectedTeam {
    h4 {
      font-family: var(--font-poppins);
      width: 100%;
      text-align: center;
      color: $white;
    }
  }

  .btn-icon {
    font-size: 2rem;
    color: $white;
    cursor: pointer;

    &:hover {
      color: $trout;
    }
  }

  .list-group {
    background: transparent;
    li {
      background: $titanWhite;
      font-family: var(--font-poppins);
      font-weight: 400;
    }
  }

  .player-slider {
    position: relative;
    overflow: hidden;
    &::before {
      content: " ";
      position: absolute;
      display: block;
      width: 100px;
      top: 0;
      left: -25px;
      height: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 50%, rgba(253, 187, 45, 0) 100%);
      z-index: 2;
    }
    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: 100px;
      top: 0;
      right: -25px;
      height: 100%;
      background: rgb(253, 187, 45);
      background: linear-gradient(90deg, rgba(253, 187, 45, 0) 0%, rgba(255, 255, 255, 1) 50%);
      z-index: 2;
    }

    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }

  img {
    max-width: 60px;
  }
}
.PercentileCell {
  padding: 0px;
  height: 35px;
  position: relative;
}

.RankCell {
  min-width: 80px !important;
  @keyframes clipAnimateLeftToRight {
    from {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      opacity: 0;
    }

    to {
      opacity: 1;
      clip-path: polygon(5% 1%, 100% 0%, 95% 100%, 0% 100%);
    }
  }

  span {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // margin: auto;
    // display: grid;
    align-items: center;
    height: 100%;
    // left: 7px;
    // display: inline-flex;
    @include sm {
      font-size: 14px;
    }
  }
  small {
    // position: absolute;
    // margin: auto;
    top: 0;
    // bottom: 0;
    // right: 7px;
    width: 36px;
    height: 26px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: $black;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    // margin-left: 1rem;
  }
}

.caret {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px;
  animation: FadeIn 0.5s ease-in-out forwards;
}

.caret.green {
  color: $green;
}
.caret.red {
  color: $red;
}

.TableSkeleton {
  .table {
    thead {
      th {
        color: transparent !important;

        span {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 90%;
          height: 70%;
        }
      }
    }
  }
}

.table {
  thead {
    tr {
      th {
        position: relative;
        .sort-icon {
          font-size: 14px;
          display: block;
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          margin: auto;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}
.TableOverlay {
  position: absolute;

  top: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(2px);
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgb(255, 255, 255) 30%);
  backdrop-filter: blur(4px);

  .TableOverlay_content {
    position: absolute;
    bottom: 55%;
    width: 100%;
    z-index: 2;
  }

  @media screen and (max-width: 767px) {
    z-index: 10;

    .TableOverlay_content {
      z-index: 10;
    }
  }
}

.Doughnut {
  height: 160px;
  width: 160px;
  position: relative;
  h2 {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    width: 50px;
    text-align: center;
    color: $trout;
    @media (max-width: 540px) {
      font-size: 26px;
    }
  }

  @media (max-width: 540px) {
    height: 40px;
    width: 40px;
  }
}

.team-schedule {
  thead th:nth-child(1) {
    min-width: 75px;
  }
  td,
  th {
    @extend .fw600;
  }
  @media (min-width: 767px) {
    thead th:nth-child(1) {
      min-width: 95px;
    }
  }
}

.tbl-td-hvr {
  td:hover {
    background: $silver;
  }
}

.graphCover {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
    box-shadow: none;
  }
}

.BracketWrapper {
  .championship {
    svg {
      font-size: 4rem;
    }
    h1 {
      font-family: var(--font-poppins);
      font-weight: bolder;
      letter-spacing: 2px;
      font-size: 3.5rem;

      $size: 20px;

      @-webkit-keyframes stripes {
        100% {
          background-position: 100px 0, 100px 0, 100px 0;
        }
      }

      @keyframes stripes {
        100% {
          background-position: 100px 0, 100px 0, 100px 0;
        }
      }

      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      text-align: center;
      font-weight: normal;
      width: 100%;
      text-align: center;

      font-size: 4rem;
      font-weight: 800;
      background: linear-gradient(
        -45deg,
        $trout 25%,
        $bronze 25%,
        $trout 50%,
        $bronze 50%,
        $trout 75%,
        $texasRose 75%,
        $trout
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: 100px 100px;
      background-position: 0 0;
      -webkit-animation: stripes 2s linear infinite;
      animation: stripes 2s linear infinite;

      @media (max-width: 767px) {
        font-size: 2.5rem;
      }
    }

    img {
      width: 200px;

      @media (max-width: 990px) {
        width: 100px;
      }
    }
  }
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: calc(35vw - 20px);
    height: calc(35vw - 20px);
    background-image: url("../assets/images/favicon.png");
    background-position: center center;
    background-size: cover;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    background-repeat: no-repeat;
    opacity: 0.1;
  }
  #tournament {
    display: flex;
    flex-direction: row;
    .round {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 200px;
      list-style: none;
      padding: 0;

      @media (max-width: 990px) {
        width: 100px;
      }
    }
    .round .spacer {
      flex-grow: 1;
    }
    .round .spacer:first-child,
    .round .spacer:last-child {
      flex-grow: 0.5;
    }

    .round .game-spacer {
      flex-grow: 1;
    }

    li.game {
      padding-left: 20px;
      height: 40px;
      display: grid;
      grid-template-columns: 1fr 40px;
      font-size: 13px;
      font-weight: 600;
      img {
        max-width: 30px;
        margin: auto;
      }
    }

    li.game span {
      float: right;
      margin-right: 5px;
    }

    li.game-top {
      border-bottom: 2px solid$darkGreyX11;
    }

    li.game-spacer {
      // border-right: 1px solid$darkGreyX11;
      min-height: 40px;
    }

    li.game-bottom {
      border-top: 2px solid$darkGreyX11;
    }

    .left-border {
      border-left: 2px solid gray;
    }

    .right-border {
      border-right: 2px solid gray;
    }

    .round-1 {
      .game-top,
      .game-bottom {
        width: 175px;

        @media (max-width: 990px) {
          width: 125px;
        }

        display: grid;
        align-items: center;
      }
    }

    li {
      .rankIndex {
        font-family: var(--font-poppins);
        font-weight: 400;
        position: absolute;
      }
    }

    li.left {
      padding-left: 10px;
      .rankIndex {
        left: -25px;
      }
    }

    li.right {
      padding-right: 10px;
      text-align: right;
      .rankIndex {
        right: -25px;
      }
      .teamName {
        text-align: left;
      }
    }

    .round-1 {
      li.winner {
        background-color: rgba(0, 128, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 128, 0, 0.2);
        }
      }
      li.game-bottom {
        background-color: rgba(255, 0, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: rgba(255, 0, 0, 0.2);
        }
      }
    }

    .round-2,
    .round-3,
    .round-4 {
      .game {
        grid-template-columns: 1fr;
      }
      .info {
        display: none;
      }
      .teamName {
        display: none;
      }
      text-align: center;
    }

    .round-2 {
      .spacer {
        flex-grow: 0.5;
        &:first-child {
          flex-grow: 0.26;
        }

        &:last-child {
          flex-grow: 0.26;
        }
      }
      .game-spacer {
        flex-grow: 1.25;
      }
    }

    .round-3 {
      .spacer {
        flex-grow: 0.85;
        &:first-child {
          flex-grow: 0.35;
        }

        &:last-child {
          flex-grow: 0.35;
        }
      }
      .game-spacer {
        flex-grow: 1.25;
      }
    }

    .round {
      overflow: visible;
      .info {
        position: absolute;
        width: 250px;
        height: 150px !important;
        height: 100%;
        text-align: center;
        opacity: 0;
        color: $black;
        z-index: -1;
        text-transform: uppercase;
        font-family: var(--font-poppins);
        background-color: $white;
        padding: 1rem;
        display: grid;
        grid-template-columns: 1fr;

        p {
          margin-bottom: 0;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 1.5rem;
          gap: 1rem;

          span {
            font-weight: unset;
          }

          small {
            font-size: 12px;
            margin-left: 2px;
          }

          &:first-child {
            p {
              font-weight: bold !important;
            }
            font-weight: bold;
          }
        }
      }

      .info_hover {
        display: block;
        position: absolute;
      }

      .game.left.game-top,
      .game-bottom {
        right: 0;

        &:hover {
          .info {
            right: -275px;
            z-index: 2;

            box-shadow: -2px 3px 33px 4px $bronze;
            -webkit-box-shadow: -2px 3px 33px 4px $bronze;
            -moz-box-shadow: -2px 3px 33px 4px $bronze;

            -webkit-animation: tilt-in-left-1 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

            animation: tilt-in-left-1 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
          }

          .BracketTooltip {
            @media (min-width: 767px) {
              right: 10vw;
              display: block;
              -webkit-animation: fade-in-bck 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
              animation: fade-in-bck 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
            }
          }
        }
      }

      .game.right.game-top,
      .game.right.game-bottom {
        left: 0;

        &:hover {
          .info {
            opacity: 1;

            left: -275px;
            z-index: 2;

            box-shadow: -2px 3px 33px 4px $bronze;
            -webkit-box-shadow: -2px 3px 33px 4px $bronze;
            -moz-box-shadow: -2px 3px 33px 4px $bronze;

            -webkit-animation: swing-in-right-fwd 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

            animation: swing-in-right-fwd 0.65s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
          }
        }
      }

      &.round-1 {
        @media (max-width: 767px) {
          .game.left {
            &:hover {
              .info {
                left: 110px;
                right: 0px;
              }
            }
          }
        }
      }
      &.round-2 .left {
        @media (max-width: 767px) {
          .game.left {
            &:hover {
              .info {
                top: 50px;
                left: 0;
                right: 0;
              }
            }
          }
        }
      }
      &.round-3 {
        @media (max-width: 767px) {
          .game.left {
            &:hover {
              .info {
                top: 50px;
                left: -200px;
                right: 0;
              }
            }
          }
        }
      }
      &.round-4 {
        @media (max-width: 767px) {
          .game.left {
            &:hover {
              .info {
                left: -200px;
                right: 0;
              }
            }
          }
        }
      }

      &.round-1 {
        @media (max-width: 767px) {
          .game.right {
            &:hover {
              .info {
                left: -230px !important;
              }
            }
          }
        }
      }
      &.round-2 {
        @media (max-width: 767px) {
          .game.right {
            &:hover {
              .info {
                left: -75px !important;
              }
            }
          }
        }
      }
      &.round-3 {
        @media (max-width: 767px) {
          .game.right {
            &:hover {
              .info {
                left: 0px !important;
              }
            }
          }
        }
      }
      &.round-4 {
        @media (max-width: 767px) {
          .game.right {
            &:hover {
              .info {
                left: 0px !important;
              }
            }
          }
        }
      }

      // .game-top,
      // .game-bottom {
      //   &:hover {
      //     .info {
      //       right: -60px;
      //       opacity: 1;
      //     }
      //   }
      // }
    }

    .round-2,
    .round-3,
    .round-4 {
      .game {
        img {
          // display: none !important;
        }
      }
    }
  }

  .region_name {
    @media (max-width: 767px) {
      padding: 0.5rem;
      text-align: center !important;
      background-color: $arsenic;
      color: $white;
      width: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: 100px;
      z-index: 3;
    }
  }

  .finalFourColumnWrapper {
    @media (min-width: 991px) {
      overflow: visible;
      position: unset;
    }
  }

  .finalFour {
    @media (max-width: 990px) {
      position: relative;
      width: 100%;
    }

    @media (min-width: 991px) {
      position: absolute;
      top: -100px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 300px;
      height: 300px;
    }

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-family: var(--font-poppins);
      letter-spacing: 3px;
    }
    .finalFour--location {
      font-size: 12px;
      font-style: italic;
    }

    .FinalFourBlock {
      margin: 10px 0px;
      p {
        height: 40px;
        margin: 0;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 35px;
        text-align: left;
        padding: 5px 10px;
        font-weight: bold;

        img {
          max-width: 30px;
          float: right;
        }
        &:first-child {
          border-bottom: 1px solid lightgray;
        }
      }
      p.top {
        cursor: pointer;
        background-color: rgba(0, 128, 0, 0.1);
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba(0, 128, 0, 0.3);
        }
      }
      p.bottom {
        cursor: pointer;
        background-color: rgba(255, 0, 0, 0.05);
        transition: background-color 0.25s ease-in-out;
        &:hover {
          background-color: rgba(177, 67, 67, 0.3);
        }
      }
    }
  }
}
@media (min-width: 450px) {
  .GameCardWrapper {
    min-width: 400px;
    width: 90%;
  }
}

@media (max-width: 399px) {
  .GameCardWrapper {
    min-width: 300px;
    width: 90%;
  }
}

.bubble-wrap {
  overflow: hidden;
  height: 600px;
}

.bubbles {
  position: relative;
  background: salmon;
}

.bubble {
  position: absolute;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 1rem 1.25rem;

  @media (max-width: 767px) {
    height: 110px;
    width: 110px;
  }
}

.slashed-price {
  text-decoration: line-through;
  color: $gray;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;
}

.coupon {
  width: 260px;
  height: 40px;
  background-color: $bronze;
  margin-bottom: 12px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  color: $white;
  font-weight: 400;

  .left-coupon {
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
  }

  .clickable {
    cursor: pointer;
  }
  .clickable:hover {
    letter-spacing: 2px;
  }

  .right-coupon {
    display: flex;
    background-color: $white;
    flex: 1;
    // border-radius: 0px 35px 35px 0px;
    border-left: none;
    height: 100%;
    position: relative;

    input {
      flex: 1;
      all: unset;
      padding-left: 6px;
      padding-right: 16px;
      border-radius: 0px 35px 35px 0px;
      box-sizing: border-box;
      border: 2px solid $bronze;
      z-index: 10;
      width: 100%;
      height: 100%;
      color: $shark;
      font-weight: 600;
      text-transform: uppercase;
    }

    .right-icon-container {
      color: $white;
      background-color: $bronze;
      height: 100%;
      margin: 0px;
      width: 50px;
      flex-shrink: 0;
      border-radius: 0px 35px 35px 0px;
      border: 2px solid $bronze;
      display: flex;
      justify-content: center;
      align-items: center;

      AiOutlineCheck {
        width: 10px;
        height: 10px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
}
.IconButton {
  min-width: 180px;
  position: relative;
}

.VALID {
  background-color: $verseGreen !important;
  transition: all 0.5s ease-in-out;
  input {
    border-color: $verseGreen !important;
  }
}

.INVALID {
  background-color: $red !important;
  input {
    border-color: $red !important;
  }
}

.anim-fade-in-forward {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.model-sandbox-buttons {
  flex-wrap: wrap-reverse;
}

@media (min-width: 768px) {
  .adaptive-dropdown-mobile {
    display: none;
  }

  .adaptive-dropdown {
    display: block;
  }
}

.matchup-output-lazyloading {
  padding-top: 0px;
}

.scores-team-name {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  @media (max-width: 1000px) {
    font-size: 14px;
  }
  font-size: 0.9rem;
  line-height: 150%;
  color: $chineseBlack;
}

.hover-score-team {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(6px);
  position: absolute;
  border-radius: 23px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;

  @media (max-width: 1001px) {
    padding: 0;
  }

  .scores-team-name-link {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: $white;
    width: 100%;
    padding: 9px 18px;
    gap: 8px;
    // margin: 5px 0;
    background: $chineseBlack;
    border-radius: 12px;
    text-align: center;
  }
}

.search-input-scores {
  border-radius: 8px;
  background: $white;
  border: 1px solid $orange;
}

.scores-games-not-found {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;

  img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: $fadedGrey;
  }

  p.scores-games-not-found-title {
    font-family: var(--font-inter);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: $fadedGrey;
    margin-bottom: 14px;
  }
}

@media (max-width: 1250px) {
  .Scoreside .DropdownHeader {
    width: auto !important;
  }
}

@include xl {
  .nav-sublink {
    display: block !important;
    background-color: $chineseBlack !important;
    position: static !important;
    box-shadow: 0 0 0px rgb(0 0 0 / 30%) !important;

    li {
      background-color: $chineseBlack !important;
      font-size: 16px;
      a {
        color: $white !important;
      }
    }
    .active-sublink a {
      background-color: $chineseBlack !important;
      color: $orange !important;
    }
  }
  .navbar .nav-item .nav-sublink li:hover a {
    background-color: transparent !important;
    color: $orange !important;
  }
}

.notFoundContainer {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notFoundHeader {
  color: $chineseBlack;
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.02em;
}

.notFoundParagraph {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $fadedGrey;
  margin-bottom: 80px;
}

.orange-go-home-button {
  width: 208px;
  height: 56px;
  background: $orange;
  border-radius: 10px;
  color: $white;
  border: none;

  font-weight: 700;
  font-size: 16px;
}

.Dropdown {
  display: inline-block;
  position: relative;
  .DropdownHeader {
    // border: 2px solid $bronze;
    color: $white;
    font-weight: bold;
    &:hover {
      border-color: transparent;
    }
  }
  .DropdownMenu {
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    top: 3.5rem;
    background: $white;
    color: $black;
    display: grid;
    grid-template-columns: 1fr;
    position: absolute;
    z-index: 2;
    width: 100%;
    border: 4px solid $trout;
    max-height: 200px;
    overflow-y: auto;

    .DropdownItem {
      padding: 0.25rem 1rem;
      line-height: 2rem;
      cursor: pointer;
      font-weight: 500;
      color: $black;
      border-bottom: 1px solid lightgray;
      &:hover {
        background: lightgray;
      }

      a {
        color: $black;
      }
    }
  }
}

.dummyLockToolTip {
  background-color: transparent !important;
  border: 0 !important;
  display: inline-block;
  text-shadow: initial !important;
  position: absolute !important;
  top: 5px;
}

.playColumnBtn {
  border-radius: 10px;
  font-weight: 700;
  padding: 11px 7px;
  width: 94px;
}

.win::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid$lightGreen;
  background-color: $caledon;
  margin-right: 8px;
}

.loss:before {
  @extend .win;
  background-color: $melon;
  border: 1px solid $tumbleweed;
}

.unexpected::before {
  @extend .win;
  background-color: $sunny;
  border: 1px solid $sunny;
}

.SummaryBox {
  background-color: $white;
  border-radius: 12px;
  align-self: center;

  &:hover {
    background-color: $blazeOrange;
    h3,
    p {
      color: $white;
    }

    .toolTip {
      display: initial;
    }
  }

  .toolTip {
    position: absolute;
    bottom: -65px;
    left: 185px;
    text-align: center;
    width: 230px;
    border: 1px solid $fadedGrey;
    border-radius: 5px;
    color: $black;
    padding: 7px 14px;
    font-size: 14px;

    .closeBtn {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 0 6px;
      border: 1px solid $fadedGrey;
      background-color: $athensGrey;
      border-radius: 50%;
    }

    &:hover {
      display: initial;
    }

    &::before {
      display: flex;
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      right: 95px;
      top: -9px;
      transform: rotate(45deg);
      background-color: $athensGrey;
      border-top: 1px solid $fadedGrey;
      border-left: 1px solid $fadedGrey;
    }
  }
}

.mrSummaryBox {
  background-color: $white;
  border-radius: 12px;

  .toolTip {
    background-color: $athensGrey;
    position: absolute;
    bottom: 0;
    left: -185px;
    text-align: center;
    width: 168px;
    border: 1px solid $fadedGrey;
    border-radius: 5px;
    color: $black;
    padding: 7px 13px;
    font-size: 14px;
    display: none;

    &:hover {
      display: initial;
    }

    &::before {
      display: flex;
      position: absolute;
      content: "";
      width: 15px;
      height: 15px;
      right: -8px;
      top: 19px;
      transform: rotate(135deg);
      background-color: $athensGrey;
      border-top: 1px solid $fadedGrey;
      border-left: 1px solid $fadedGrey;
    }
  }

  &:hover .toolTip {
    display: initial;
  }
}

.Overall {
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @include sm {
    flex-direction: column;
  }
}

#export-button-wrapper {
  @include sm {
    flex-direction: column;
    align-items: center;
  }
}

.exportBtn {
  @include sm {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
}

#pov-buttons {
  @include sm {
    flex-direction: column;
    width: 206px;
    margin: auto;
    margin-bottom: 33px;
    padding-right: 0;
    padding-left: 0;

    p {
      font-size: 14px;
      padding-left: 15px;
      margin-right: 0;
    }

    .pointsValueNumbers {
      padding: 12px 15px;
      margin: auto;
      background-color: $white;
      width: 100%;
      text-align: center;
    }
  }
}

.teamStandingsTitle {
  font-family: var(--font-inter);
  font-size: 36px;
  font-weight: 700;

  img {
    margin-right: 24px;
  }

  // @include xl {
  //   font-size: 42px;
  // }

  @include sm {
    font-size: 28px;
  }
}

.versusTitle {
  color: $white;
  text-align: center !important;
  margin-right: auto;
  width: calc(100% - 185px);
  font-family: var(--font-oswald);
  font-size: 44px;
  font-weight: 700;

  @include sm {
    font-size: 24px;
    margin: auto;
  }
}

.outputBackBtn {
  background: transparent;
  border: 0;
  color: $white;
  font-size: 16px;
  font-weight: 700;

  @include sm {
    width: 80px;
  }
}

@media screen and (max-width: 630px) {
  .model-results-buttons {
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .model-results-search {
    margin-top: 30px;
  }
}

.trendFinderDummyLock {
  .dummyLockToolTip {
    top: 0;
    left: 40%;
  }

  .tooltipCon {
    position: absolute;
    bottom: 100%;
    // left: 0;
    color: $darkGrey;
    padding: 0 15px 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    min-width: 200px;
    text-align: left;
    display: none;

    background-color: $white;
    overflow-y: auto;
    @include border-radius(5px 5px 0 0);
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.1));
    b {
      font-weight: 600;
      margin-bottom: 5px;
      padding: 10px 15px;
      font-size: 14px;
      color: $darkGrey;
      background-color: $antiFlashWhite;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $antiFlashWhite;
    }

    &::-webkit-scrollbar-thumb {
      background: $taupeGray;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $davysGrey;
    }
  }
  &:last-child {
    .tooltipCon {
      left: auto;
      right: 0;
    }
  }
  &:hover {
    .tooltipCon {
      display: block;
    }
  }
}

.mostPopular {
  padding: 10px;
  background-image: url("../assets/images/mostPopularBg.png");
  border-radius: 12px;
}

.bookmakerWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    height: 40px;
    display: flex;
    padding: 0 25px;
    position: relative;

    span,
    img {
      margin: auto;
    }

    .bookmakerHint {
      position: absolute;
      bottom: 45px;
      left: -5px;
      text-align: center;
      border: 1px solid $fadedGrey;
      background-color: $athensGrey;
      border-radius: 5px;
      color: $black;
      padding: 8px 14px;
      font-size: 14px;
      font-weight: 700;
      display: none;

      &::before {
        display: flex;
        position: absolute;
        background-color: $athensGrey;
        content: "";
        width: 7px;
        height: 7px;
        right: 40px;
        bottom: -4px;
        transform: rotate(225deg);
        background-color: $athensGrey;
        border-top: 1px solid $fadedGrey;
        border-left: 1px solid $fadedGrey;
      }
    }

    &:hover .bookmakerHint {
      display: initial;
    }
  }
}

.ValueBracket {
  background-color: $athensGrey;
  .ValueBracketTitle {
    margin-top: 55px;
    margin-bottom: 52px;
    h2 {
      font-weight: 700;
      line-height: 54px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $fadedGrey;
    }
  }

  .dummyLockWrapper {
    position: relative;
    margin-bottom: 100px;

    .imgWrapper {
      filter: blur(3px);
      img.bracketBackground {
        object-fit: none;
        object-position: top;
        margin: auto;
        height: 450px;
        width: 100%;
        filter: blur(8px);
      }

      &::after {
        width: 100%;
        height: 450px;
        position: absolute;
        top: 0px;
        left: -4px;
        content: "";
        background: linear-gradient(0deg, rgba(248, 249, 251, 0) 0%, $ghostWhite 100%);
      }
    }
    .dummyLock {
      padding: 0 25px;
      margin: auto;
      position: absolute;
      top: -10%;
      left: 32%;
      width: 596px;
      padding: 0;

      h3 {
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 16px;
      }

      .requestEmailWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 572px;
        margin: auto;

        .email-input {
          width: 360px;
          height: 50px;
          padding-left: 44px;
          border: 1px solid $lightGrey;
          border-radius: 8px;
        }

        span.form-icon {
          top: 12px;
          left: 19px;
        }

        .sendEmailBtn {
          height: 56px;
          width: 182px;
          border-radius: 10px;
          padding: 16px 46px;
          gap: 10px;
          background-color: $fadedGrey;
          color: $white;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

.dummyLockWrapper {
  position: relative;
  margin-bottom: 100px;

  .imgWrapper {
    filter: blur(3px);
    img.bracketBackground {
      object-fit: none;
      object-position: top;
      margin: auto;
      height: 450px;
      width: 100%;
      filter: blur(8px);
    }

    &::after {
      width: 100%;
      height: 450px;
      position: absolute;
      top: 0px;
      left: -4px;
      content: "";
      background: linear-gradient(0deg, rgba(248, 249, 251, 0) 0%, $ghostWhite 100%);
    }
  }
  .dummyLock {
    padding: 0 25px;
    margin: auto;
    position: absolute;
    top: -10%;
    left: 32%;
    width: 596px;
    padding: 0;

    h3 {
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 16px;
    }

    .requestEmailWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 572px;
      margin: auto;

      .email-input {
        width: 360px;
        height: 50px;
        padding-left: 44px;
        border: 1px solid $lightGrey;
        border-radius: 8px;
      }

      span.form-icon {
        top: 12px;
        left: 19px;
      }

      .sendEmailBtn {
        height: 56px;
        width: 182px;
        border-radius: 10px;
        padding: 16px 46px;
        gap: 10px;
        background-color: $fadedGrey;
        color: $white;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.bookmakerHeader {
  tr {
    th {
      span {
        font-weight: 700;
        font-size: 12px;
        color: $white;
      }

      img {
        max-height: 24px;
      }

      &:first-child {
        border-radius: 12px 0 0 0;
      }
      &:last-child {
        border-radius: 0 12px 0 0;
      }
    }
  }
}

.years {
  align-items: baseline;
  margin-top: 30px;

  button {
    font-family: var(--font-inter);
    font-weight: 700;
    font-size: 20px;
    color: $fadedGrey;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $chineseBlack;
      padding-bottom: 10px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: $orange;
        height: 4px;
        border-radius: 2px;
      }
    }
  }
}

.percentileRow {
  td {
    padding-top: 12px !important;
  }
}

.recharts-surface {
  overflow: visible;
}

.live-header-regular-text {
  font-family: var(--font-inter);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $fadedGrey;
}

.cancel-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 70vw;
  background-color: $white;
  border-radius: 10px;
  gap: 3rem;
  margin: 2rem auto;
  padding: 0 3rem;
  text-align: center;

  .btn {
    color: $black;
    background-color: $white;
    border: 1px solid $black;
  }
}
.mobile-view-show {
  display: none;
}
.mobile-view-show.best-bets {
  display: revert;
  .mobile-header {
    display: none;
  }
}

@media (max-width: 768px) {
  .mobile-view-hide {
    display: none !important;
  }

  .mobile-view-show {
    display: block;
  }
  .mobile-view-show.best-bets {
    display: flex;
    padding: 2rem 0rem;
    background-color: $white !important;
    .mobile-header {
      display: block !important;
      margin: 10px 0 !important;
      margin-bottom: 1rem !important;
    }
    td {
      max-width: 100% !important;
      border-top: none !important;
      text-align: left !important;
      height: auto !important;
      padding: 0 1.2rem !important;
    }
    td.date-time {
      font-weight: 700;
    }
    td.home-away-team {
      margin: 15px 0px;
      border-bottom: 2px solid $timberwolf !important;
      padding-bottom: 1rem !important;
      img {
        border: 1px solid $gray;
        border-radius: 50%;
        padding: 4px 0px;
      }
    }
    td.projected-winner {
      img {
        border: 1px solid $gray;
        border-radius: 50%;
        padding: 4px 0px;
        margin-right: 5px;
      }
      span {
        margin: -0.3rem 0;
        display: block;
      }
    }
  }
  .mobile-view-show {
    // padding: 2rem;
    display: flex;
    flex-direction: column;
    // gap: 1rem;
    border-radius: 20px;
    margin-bottom: 1rem;
    .row {
      padding: 1rem 2rem;
      border-bottom: 1px solid $platinum;
    }
    .row:last-child {
      border-bottom: none;
    }
    .points-range {
      display: inline-flex;
      align-items: baseline;
      justify-content: center;
      padding-bottom: 1rem;
      gap: 2px;
    }
  }
  .playerprops-mobile-view {
  }
}
.mobile-view-hide img {
  height: auto !important;
}

.linevalue-page {
  .outer {
    margin-top: -4rem;
    padding-top: 5rem;
    .tooltipCon {
      right: 30px !important;
      bottom: 0 !important;
      z-index: 9999 !important;
    }
  }
}

.sphere-3d {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: inset -1px -5px 8px rgba(0, 0, 0, 0.5);
}

.api-access {
  .desc {
    color: $black;
    font-size: 12px;
  }
  .access-key-box {
    background: $lavender;
    border-radius: 5px;
    padding: 8px 17px;
  }
  table tr:nth-child(2) td {
    padding-top: 1rem;
  }
  table tr td {
    color: $black !important;
    padding-top: 1rem;
    .access-desc {
      font-size: 12px;
      color: $black;
    }
  }
}
.winning-metrics {
  text-align: left !important;
}

.faq-accordion {
  padding: 15px 15px;
  margin-bottom: 20px; // Added margin for more space between accordion items
  min-height: 20px; // Example value, adjust as necessary for uniform size
  background-color: $lightGrey;

  background-color: $bgcolor;

  &:first-child {
    // border-top: 1px solid $lightGrey;
  }
  .faq-item {
    padding-right: 15px;

    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: $chineseBlack;
    cursor: pointer;
  }

  .close-icon {
    position: relative;
    width: 36px;
    height: 36px;
    background: $white;
    box-shadow: 2px 4px 30px rgba(107, 108, 114, 0.12);
    border-radius: 18px;
    transform: matrix(-1, 0, 0, 1, 0, 0);

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 14px;
      height: 2px;
      background-color: $chineseBlack;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 2px;
      height: 14px;
      background-color: $chineseBlack;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .active-accordion {
    &:after {
      height: 0px;
    }
  }

  .accordion-content-active {
    margin-top: 22px;
    height: 100%;
    overflow: hidden;
    transition: height 1.5s 0.5s;
    font-size: 16px;
    font-weight: 400;
    color: $chineseBlack;
  }

  .accordion-content {
    height: 0px;
    overflow: hidden;
  }
}
.checkout-form {
  input {
    background-color: $lightGrey;
  }
  label {
    color: "black";
  }
  a {
    color: "black";
  }
}
.live-header-navbar {
  display: flex;
  margin: 0px 0px;
  overflow-x: auto;
  justify-content: flex-start;
  // min-height: 25px;
  padding: 0px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 0.4rem;
  }
  .live-header-league-menu {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    background: $white;
    border-right: 1px solid $platinum;
    align-self: flex-start;
    justify-content: center;

    .live-header-league-menu-item {
      padding: 8px 2px;
      border-bottom: 1px solid $platinum;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: $black;
      cursor: pointer;
      &.active {
        color: $orange;
        border-bottom: 2px solid $orange;
      }
    }
    .live-header-league-menu-item:first-child:nth-last-child(2),
    .live-header-league-menu-item:first-child:nth-last-child(2) ~ .live-header-league-menu-item {
      padding: 17px 2px;
    }
    .live-header-league-menu-item:first-child:nth-last-child(1) {
      padding: 2.8rem 2px;
    }
  }
  table {
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    display: block;
    padding: 0.4rem;
    padding-bottom: 2px;
    font-size: 14px;
    th,
    td {
      padding: 0 0.2rem;
      padding-bottom: 0.4rem;
    }
    .link-btn-td {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }
    .team-name {
      display: flex;
      align-items: center;
      margin-top: 0px;
      padding-top: 0px;
    }
    .score-td {
      padding-top: 0;
      font-size: 12px;
      vertical-align: top;
      text-align: center;
    }
    tr:nth-child(2) td {
      padding-top: 0 !important;
    }
  }
}
.live-header-hide-show-btn {
  position: absolute;
  right: 0;
  bottom: -29px;
  font-weight: 500;
  font-size: 12px;
  color: #6c757d;
  color: black;
  gap: 3px;
  z-index: 7;
}
.select-date-filter-dropdown {
  left: 0;
  right: auto;
}
.line-value-stats {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .heading {
    // background: $chineseBlack;
    // background: $orange;
    // color: $white;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 3px;
  }
  .box {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    border-radius: 0.2rem;
    min-height: 5.3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: stretch;
    padding: 0.5rem 0.09rem;
    * {
      margin: 0px !important;
      padding: 0px !important;
    }
  }
}
.non-logged-in-user {
  div > h5 {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  h5 svg {
    color: $orange;
  }
  .gameCardList {
    padding: 5px !important;
    .gameCard {
      margin-top: 0px !important;
      h5 {
        justify-content: center !important;
      }
    }
  }
  .accordion-container.frequently-asked {
    background: #f4f5f9;
    .faq-accordion {
      background-color: white;
      margin-bottom: 0.6rem;
    }
  }
}
.custom-dot-list-style {
  li {
    display: inline;
    width: auto !important;
  }
}
.team-logo {
  width: 80px; /* Adjust size as needed */
  margin-bottom: 10px;
}

.team-name {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.score {
  font-size: 2em;
  font-weight: bold;
}

.game-time,
.status,
.live-indicator {
  font-size: 0.9em;
  margin-bottom: 5px;
}

//  /<league>/pics
.container-picks-and-predictions {
  & > div {
    margin-top: 1rem;
  }
  @media screen and (min-width: $lg) {
    margin: 0 1rem;
  }
}
.call-to-action-picks-and-predictions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background-color: $chineseBlack;
  padding: 0 10px;
  font-size: 1.25rem;
  @media screen and (max-width: 768px) {
    // height: 8rem;
    padding-bottom: 1rem;
  }
  .mid-text {
    color: $lightGrey;
    flex: 1;
    padding-left: 1rem;
  }
  .call-to-action {
    background-color: $lightGrey;
    padding: 10px 20px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
      margin: auto;
    }
  }
}

.games-picks-and-predictions {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.card-picks-and-predictions {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 470px;
  background-color: $white;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  // padding-top: 1rem;
  .team-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0px 1rem;

    .picks-team-name {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 700;
      word-break: break-all;
      .picks-team-logo {
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
  .date-time {
    background-color: rgb(232, 232, 232);
    margin: 0 auto;
    font-size: 0.75rem;
    font-weight: bold;
    color: darkgrey;
    padding: 0.5rem 1rem;
    margin-bottom: 10px;
  }
  .black-ribbon {
    background-color: $chineseBlack;
    color: $lightGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 10px;
  }
  .description {
    padding: 10px;
  }
  .lines {
    padding: 0 10px;
    * {
      padding: 0px;
    }
  }
  .card-button {
    background-color: green;
    color: $white;
    text-align: center;
    margin: 0 auto;
    padding: 0.75rem 1.75rem;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
  }
  .card-link {
    color: $lightGrey;
    text-align: center;
  }
}

.about-picks-and-predictions {
  background-color: white;
  padding: 1rem;
  p {
    color: black;
  }
}
.smart-card-view {
  background-color: $white;
  border-radius: 2rem;

  .lock {
    .tooltipCon {
      @include tooltipConMixin;
    }
    &:last-child {
      .tooltipCon {
        left: auto;
        right: 0;
      }
    }
    &:hover {
      .tooltipCon {
        display: block;
      }
    }
  }
}

.blog-card {
  p {
    // color: currentColor;
    color: $black;
  }
  li p {
    display: inline-block;
  }
  img {
    display: block;
    margin: auto;
  }
}
.scroll-table {
  display: block;
  overflow-y: auto;
}

.scroll-table thead {
  position: sticky;
  top: 0;
  background-color: #343a40;
  color: $white;
  z-index: 1000;
}
.package-card {
  min-width: 250px;
  max-width: 300px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  &:hover {
    background: $orange;
    .text-orange {
      color: $white;
    }
  }
  img {
    mix-blend-mode: multiply;
  }
}
.price-block {
  position: relative;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 4, 0.9752275910364145) 0%,
    rgba(1, 1, 25, 0.9304096638655462) 62%,
    rgba(0, 0, 37, 0.9444152661064426) 100%
  );
  .container {
    max-width: 1024px;
  }
  .row * {
    z-index: 9;
    color: $white;
    .package-card {
      background: $white;
      width: 100%;
      max-width: 100%;
      & * {
        color: black !important;
      }
    }
    .package-card:hover {
      background: $orange;
      & * {
        color: white !important;
        stroke: white !important;
      }
    }
  }
  .price-block-bg-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 70%;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.1;
      filter: blur(3px);
    }
  }
  svg {
    display: block;
    min-width: 2rem;
  }
}

.AppContentQuarterTableContainer {
  color: #fff;
  margin-top: 1rem;
  .ScoreToggle {
    width: fit-content;
    background: #262930;
    border-radius: 8px;
    color: #fff;
    button {
      border-radius: 0px;
      background: none;
      margin: 0;
      color: #fff;
      padding: 0.5rem 1rem;
    }
  }
  .ScoreToggle [data-active="true"] {
    background: #f26b0b;
    border-radius: 8px;
  }
  .AppContentQuarterTable table {
    background: #262930;
    border-radius: 8px;
    th {
      padding: 0.8rem;
    }
    td {
      padding: 0.4rem;
    }
  }
  .AppFooterTableBreak {
    padding: 0rem 1.4rem !important;
    div {
      background: white;
      height: 1px;
    }
  }
  @media only screen and (max-width: 768px) {
    .ScoreToggle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 1rem;
    }
    .ScoreToggle button {
      width: 50%;
      font-size: 14px;
      padding: 0.5rem 0;
      margin: 0;
    }
  }
}
.game-page-box-scores {
  .table tr:last-child td {
    font-weight: 900;
    background-color: #262930 !important;
    color: white;
  }
  tr:last-child td:last-child,
  tr:last-child td:first-child {
    border-radius: 0px !important;
  }
}
.table-responsive.responsive-to-card,
.scroll-table.responsive-to-card {
  @media (max-width: #{$sm}) {
    table thead {
      display: none;
    }
    table tbody {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
    }
    table tbody tr,
    table tbody td {
      display: block;
      border: 0;
      background-color: $white;
    }
    table tbody tr:nth-child(even),
    table tbody tr:nth-child(even) td {
      background-color: $lightGrey;
    }
    table tbody td {
      padding: 4px;
      font-weight: bold;
      text-align: left !important;
      width: 33%;
      display: inline-block;
      height: auto;
      &.sm-w-100 {
        width: 100%;
      }
      .Percentile {
        height: auto;
        position: relative;
      }
      .arrow {
        position: relative;
        left: 0;
        margin-left: 0;
        margin-right: 0;
      }
    }
    table tbody td:before {
      content: attr(data-name);
      width: 100%;
      display: inline-block;
      text-transform: capitalize;
      font-weight: normal;
    }

    table tbody td.status {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    table tbody tr {
      position: relative;
      width: calc(100% - 8px);
      border: 1px solid #e7e7e7;
      padding: 8px;
      margin: 4px;
      text-align: left;
    }
  }
}
